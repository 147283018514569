@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
  font-family: lato !important;
}

/*Font*/
@font-face {
  font-family: gothammedium;
  src: url("/assets/fonts/Gotham-Medium.ttf");
}
@font-face {
  font-family: gothammedium;
  src: url("/assets/fonts/Gotham-Medium.eot");
}
@font-face {
  font-family: gothammedium;
  src: url("/assets/fonts/Gotham-Medium.woff");
} 

@font-face {
  font-family: latomedium;
  src: url("/assets/fonts/Lato-Medium.ttf");
}

@font-face {
  font-family: lato;
  src: url("/assets/fonts/Lato-Reg.ttf");
}

@font-face {
  font-family: latomediumitalic;
  src: url("/assets/fonts/Lato-MediumItalic.ttf");
}

@font-face {
  font-family: gothambold;
  src: url("/assets/fonts/Gotham-Bold.ttf");
}

@font-face {
  font-family: latosemibold;
  src: url("/assets/fonts/Lato-Semibold.ttf");
}

@font-face {
  font-family: latobold;
  src: url("/assets/fonts/Lato-Bol.ttf");
}

body {
  background-color: #f7f7f7;
  font-family: lato;
  font-size: 13px;
  color: #666666;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

button {
  outline: 0px none;
}
button:hover {
  outline: 0px none;
}
a {
  outline: 0px none;
}
a:hover {
  outline: 0px none;
}
simple-snack-bar.mat-simple-snackbar {
  font-family: latomedium;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  display: block;
}
snack-bar-container {
  text-align: center;
}
snack-bar-container.edi-success-snackbar {
  background-color: #acf0c9;
  border: 1px solid #007d35;
  color: #007d35;
  border-radius: 0;
  min-width: 300px;
  box-sizing: border-box;
}
snack-bar-container.edi-error-snackbar {
  background-color: #f4b6b9;
  border: 1px solid #ff000f;
  color: #960009;
  border-radius: 0;
  min-width: 300px;
  box-sizing: border-box;
}

/*Common.css*/
.edi-header-wrapper {
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  padding: 30px 36px;
  padding-bottom: 0;
}
.edi-page-wrapper {
  position: fixed;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.edi-page-wrapper .edi-page-container {
  display: flex;
  flex: 1;
  padding: 20px 50px;
  padding-top: 0px;
}
.edi-page-wrapper .edi-page-container .edi-sidebar {
  width: 250px;
  transition: margin-left 250ms ease-out;
  display: flex;
  flex-direction: column;
}
.edi-page-wrapper .edi-page-container .edi-main {
  padding: 0 0 0 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  z-index: 0;
}
.edi-page-wrapper .edi-page-container .edi-main .edi-main-container {
  border-left: 1px solid #ffffff;
  overflow-y: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.edi-filter-bar {
  padding: 0 0 0 0px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  position: relative;
  font-family: lato;
  font-size: 13px;
  color: #666666;
}
.edi-filter-bar .breadcrumb-section {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.edi-filter-bar .filter-section {
  display: flex;
}
.edi-filter-bar .breadcrumb-section a {
  color: #448ee1;
  text-decoration: none;
}
.edi-filter-bar .breadcrumb-section a:hover {
  color: #448ee1;
  text-decoration: underline;
}
.edi-filter-bar .breadcrumb-section .download-section {
  display: flex;
  margin-left: 70px;
}
.edi-filter-bar .breadcrumb-section .download-section a {
  font-family: lato;
  font-size: 13px;
  color: #666666;
  display: flex;
  align-items: center;
}
.edi-filter-bar .breadcrumb-section .download-section i {
  margin-left: 10px;
  background-image: url(../../assets/images/edi-download.png);
  background-repeat: no-repeat;
  width: 13px;
  height: 11px;
}
.edi-filter-bar .filter-section li.createmanifests a,
.save-button {
  padding: 0 10px;
  width: auto;
  height: auto;
  border: 2px solid #000000;
  line-height: 30px;
  font-family: gothambold;
  font-size: 10px;
  color: #000000;
  text-transform: uppercase;
}
.edi-filter-bar .filter-section li.createmanifests a:hover,
.save-button:hover {
  background-color: #000;
  color: #fff;
}
/*common.css*/

/*Header Css*/
.header-block {
  display: flex;
  flex: 1 1 auto;
}
.header-block .edi-search {
  display: flex;
}
.header-block .edi-account-detail button.mat-button {
  background-color: transparent;
  min-width: auto;
  border-radius: 0;
}
.header-block .edi-account-detail button.mat-button:hover {
  background-color: transparent;
}
.mat-button:hover .mat-button-focus-overlay,
.mat-stroked-button:hover .mat-button-focus-overlay {
  opacity: 0 !important;
}
.edi-header-wrapper .edi-account-detail .mat-button-wrapper span {
  padding: 4px;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: gothammedium;
  font-size: 11px;
  color: #fff;
  background-color: #e01d3b;
  border: 2px solid #7b0013;
}
.edi-header-wrapper .edi-account-detail .mat-button-wrapper:hover {
  background-color: transparent;
}
.header-block
  .edi-search
  .mat-form-field.mat-focused.mat-primary
  .mat-select-arrow {
  color: #ea333e !important;
}
.header-block .edi-search .mat-form-field .mat-select-arrow {
  color: #ea333e !important;
}
.header-block .edi-search .mat-form-field-ripple {
  background-color: #ea333e !important;
}
.header-block .edi-search .mat-focused .mat-form-field-label {
  color: #ea333e;
}
.header-block .edi-search .mat-select-value {
  color: rgba(68, 135, 225, 1) !important;
}

.header-block
  .search-section
  .mat-form-field.mat-focused.mat-primary
  .mat-select-arrow {
  color: #ea333e !important;
}
.header-block .search-section .mat-form-field .mat-select-arrow {
  color: #ea333e !important;
}
.header-block .search-section .mat-form-field-ripple {
  background-color: #ea333e !important;
}
.header-block .search-section .mat-focused .mat-form-field-label {
  color: #ea333e;
}
.header-block .search-section .mat-select-value {
  color: rgba(68, 135, 225, 1) !important;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(68, 135, 225, 1) !important;
}

.header-block .logo {
  margin-right: 60px;
}
.header-block .search-section {
  display: flex;
}
.header-block .search-section .edi-search-select {
  font-family: latomedium;
  font-size: 13px;
  color: #333333;
  margin-left: 20px;
}
.header-block .search-section .edi-search-select label {
  font-family: latomedium;
}
.header-block .search-section .edi-search-input {
  font-family: latomedium;
  font-size: 13px;
  color: #333333;
  margin-left: 20px;
}
.header-block .search-section .edi-search-input label {
  font-family: latomedium;
}
.header-block .search-section .edi-search-input .mat-form-field-infix {
  display: flex;
}
.header-block .search-section .edi-search-input .mat-form-field-label {
  left: 22px;
  top: 17px;
}
.header-block .search-section .edi-search-input .mat-icon {
  height: 14px;
  font-size: 14px;
}
/*Header Css*/

/*Footer*/
.footer-section {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  margin: 0px 0 20px;
}
.footer-section a {
  padding-left: 15px;
  margin-left: 7px;
  background-image: url(../../assets/images/eshopbox-footer.jpg);
  background-repeat: no-repeat;
  height: 10px;
  text-decoration: none;
  text-transform: none;
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #999999;
}
/*Footer*/

/*Sidebar Css*/
.edi-sidebar-links {
  margin-top: 31px;
  display: flex;
  font-family: latomedium;
  font-size: 13px;
  color: #666666;
  flex-direction: column;
}
.edi-sidebar-links li a {
  text-decoration: none;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: latomedium;
  font-size: 13px;
  color: #666666;
}
.edi-sidebar-links li a:hover {
  background-color: #448ee1;
  color: #fff;
}
.edi-sidebar-links li a.active {
  background-color: #448ee1;
  color: #fff;
}
.edi-sidebar-links li a i {
  margin-right: 17px;
  background-image: url(../../assets/images/sidebar-icons.png);
  background-repeat: no-repeat;
  width: 19px;
  height: 23px;
}
.edi-sidebar-links li.reports a i {
  background-position: 0 0;
}
.edi-sidebar-links li.reports a:hover i {
  background-position: 0 -27px;
}
.edi-sidebar-links li.reports a.active i {
  background-position: 0 -27px;
}

.edi-sidebar-links li.shipments a i {
  background-position: -24px 0;
}
.edi-sidebar-links li.shipments a:hover i {
  background-position: -24px -27px;
}
.edi-sidebar-links li.shipments a.active i {
  background-position: -24px -27px;
}
.edi-sidebar-links li.manifests a i {
  background-position: -50px 0;
}
.edi-sidebar-links li.manifests a:hover i {
  background-position: -50px -27px;
}
.edi-sidebar-links li.manifests a.active i {
  background-position: -50px -27px;
}

.edi-sidebar-links li.settings a i {
  background-position: -130px 0;
  width: 22px;
}
.edi-sidebar-links li.settings a:hover i,
.edi-sidebar-links li.settings a.active i {
  background-position: -130px -27px;
}

.edi-sidebar-links li.issues a i {
  background-position: -158px 0;
  width: 22px;
}
.edi-sidebar-links li.issues a:hover i,
.edi-sidebar-links li.issues a.active i {
  background-position: -158px -27px;
}
/*Sidebar Css*/

/*filterbar Css*/
.edi-filterbar-links {
  align-items: center;
  margin-top: 0px;
  display: flex;
  font-family: latomedium;
  font-size: 13px;
  color: #666666;
  flex-direction: row;
  position: absolute;
  right: 0;
}
.edi-filterbar-links li {
  margin-left: 10px;
}
.edi-filterbar-links li:first-child {
  margin-left: 0px;
}
.edi-filterbar-links li a {
  text-decoration: none;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0px;
  font-family: latomedium;
  font-size: 13px;
  color: #666666;
}
.edi-filterbar-links li a {
  width: 22px;
  height: 21px;
}
.edi-filterbar-links li.edit a {
  background-image: url(../../assets/images/edi-filter-edit.png);
  background-repeat: no-repeat;
  background-position: center;
}
.edi-filterbar-links li.printer a {
  background-image: url(../../assets/images/edi-filter-printer.png);
  background-repeat: no-repeat;
  background-position: center;
}
.edi-filterbar-links li.barcode a {
  background-image: url(../../assets/images/edi-filter-barcode.png);
  background-repeat: no-repeat;
  background-position: center;
}
.edi-filterbar-links li.close a {
  background-image: url(../../assets/images/edi-filter-close.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #cfcfcf;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.edi-filterbar-links li.save a {
  background-image: url(../../assets/images/edi-filter-tick.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #00c853;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.edi-filterbar-links li.truck a {
  background-image: url(../../assets/images/edi-filter-truck.png);
  background-repeat: no-repeat;
  background-position: center;
}
.edi-filterbar-links li.reports a {
  background-image: url(../../assets/images/edi-filter-reports.png);
  background-repeat: no-repeat;
  background-position: center;
}
.edi-filterbar-links li.round-close a {
  background-image: url(../../assets/images/edi-filter-round-close.png);
  background-repeat: no-repeat;
  background-position: center;
}
/*filterbar Css*/

/*Shipments*/
.shipments-table {
  display: flex;
  font-family: latomedium;
  font-size: 13px;
  color: #666666;
}
.shipments-table ul {
  align-items: center;
  display: flex;
  font-family: latomedium;
  font-size: 12px;
  color: #999999;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.shipments-table ul li .title {
  font-family: latomedium;
  font-size: 12px;
  color: #999999;
  min-width: 150px;
}
.shipments-table ul li .value {
  font-family: latomedium;
  font-size: 13px;
  color: #333333;
  min-width: 150px;
}
.shipments-table ul li .value.giftvalue {
  color: #cc1c27;
}
.shipments-table ul li i.gift-icon {
  margin-left: 10px;
  background-image: url(../../assets/images/edi-gift.png);
  background-repeat: no-repeat;
  width: 18px;
  height: 16px;
  display: inline-flex;
}
.shipments-table.editcase ul li i.gift-icon {
  margin-left: 10px;
  background-image: url(../../assets/images/edi-gift-red.png);
  background-repeat: no-repeat;
  width: 18px;
  height: 16px;
  display: inline-flex;
}
.shipments-table ul li {
  align-items: center;
  margin: 0 0 50px 0px;
  display: flex;
}
.timeleft {
  padding: 5px 10px;
  color: #1e8d1b;
  font-size: 12px;
  border: 1px solid #1e8d1b;
  width: 70px;
  text-align: center;
  margin-left: 120px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.shipments-table ul li.foredit {
  display: none;
}
.shipments-table input[type="text"] {
  max-width: 35px;
  width: 100%;
  border: 1px solid #cfcfcf;
  background-color: #f7f7f7;
  font-size: 13px;
  color: #333333;
  font-family: latomedium;
  padding: 5px;
}
.shipments-table.editcase input[type="text"] {
  max-width: 35px;
  width: 100%;
  border: 1px solid #cfcfcf;
  background-color: #f7f7f7;
  font-size: 13px;
  color: #333333;
  font-family: latomedium;
  padding: 5px;
}
.shipments-table.editcase input[type="text"]:focus {
  outline: 0px none;
}
.shipments-table.editcase .package-type-select {
  font-size: 13px;
  color: #333333;
  font-family: latomedium;
}
.shipments-table.editcase .foredit {
  display: flex;
}
.shipments-table.editcase .fordefault {
  display: none;
}

.shipments-items {
  display: flex;
  font-family: latomedium;
  font-size: 13px;
  color: #666666;
  flex-direction: column;
}
.shipments-items-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.shipments-items-header div:first-child {
  flex: 1 1 auto;
  font-family: latomedium;
  font-size: 13px;
  color: #333333;
}
.shipments-items-header div:last-child {
  font-family: latomediumitalic;
  font-size: 12px;
  color: #d92a34;
}
.shipments-items table {
  flex-shrink: 0;
  border-collapse: collapse;
  border: 1px solid #dadada;
  width: 100%;
}
.shipments-items table th {
  font-family: latomedium;
  font-size: 13px;
  color: #000000;
  text-align: left;
  font-weight: normal;
  background-color: #fbfbfb;
  padding: 10px 20px;
}
.shipments-items table td {
  font-family: lato;
  vertical-align: top;
  font-size: 12px;
  color: #000000;
  text-align: left;
  padding: 15px 20px;
  border-bottom: 1px solid #e5e5e5;
}
.shipments-items table td .thumb {
  display: inline-flex;
  vertical-align: top;
  border: 1px solid #e5e5e5;
  margin-right: 10px;
}
.shipments-items table td .thumb-title {
  display: inline-flex;
  vertical-align: top;
}
.shipments-items table tr:last-child td {
  border-bottom: 0px none;
}
/*Shipments*/

/*manifests*/
.manifests-section {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}
.manifests-section .manifests-table {
  display: block;
}
.manifests-section .manifests-table table {
  min-width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  display: table;
}
.manifests-section .manifests-table table th {
  min-width: 100px;
  font-weight: normal;
  height: 0;
  position: initial;
  padding-bottom: 0;
  padding-top: 0;
  display: table-cell;
}
.manifests-section .manifests-table table .thead {
  height: 40px;
  top: 0;
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  text-align: left;
  font-family: latomedium;
  font-size: 14px;
  color: #000000;
  font-weight: normal;
  background-color: #eee;
}
.manifests-section .manifests-table table .trow {
  display: flex;
  padding: 12px;
  font-family: lato;
  font-size: 12px;
  color: #666666;
  border-bottom: 1px solid #eeeeee;
}
.manifests-section .manifests-table table tbody {
  border-left: 1px solid #eeeeee;
}
.manifests-section .manifests-table table .filter-icon a {
  display: flex;
  background-image: url(../../assets/images/edi-filter.png);
  background-repeat: no-repeat;
  width: 10px;
  height: 12px;
  margin-left: 10px;
}
/*manifests*/

/*Create manifests Dialog*/
.dialog-wrapper {
  display: flex;
  flex-direction: column;
}
.dialog-wrapper .dialog-inner {
  display: flex;
  padding: 28px;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.dialog-wrapper .dialog-inner .title {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: latosemibold;
  font-size: 22px;
  color: #000000;
  margin-bottom: 40px;
}
.dialog-wrapper .dialog-inner ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dialog-wrapper .dialog-inner ul li {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.dialog-wrapper .dialog-inner ul li label {
  font-family: latomedium;
  font-size: 13px;
  color: #333333;
  margin-bottom: 0px;
}
.dialog-wrapper .dialog-inner ul.comments-box li {
  width: 100%;
}
.dialog-wrapper .dialog-inner ul.comments-box li textarea {
  resize: none;
}
.dialog-wrapper .dialog-inner .mat-form-field-infix {
  width: 240px;
}
.dialog-wrapper .dialog-inner .actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.dialog-wrapper .dialog-inner .actions button {
  background-color: transparent;
  cursor: pointer;
  padding: 0 25px;
  width: auto;
  height: auto;
  border: 2px solid #000000;
  line-height: 35px;
  font-family: gothambold;
  font-size: 10px;
  color: #000000;
  text-transform: uppercase;
}
.dialog-wrapper .dialog-inner .actions button:hover {
  background-color: #000;
  color: #fff;
}
.dialog-wrapper .dialog-inner .dialog-close {
  cursor: pointer;
  border: 0px none;
  background-color: transparent;
  outline: 0px none;
  position: absolute;
  right: 0px;
  top: 0px;
  background-image: url(../../assets/images/edi-close.png);
  background-repeat: no-repeat;
  width: 14px;
  height: 13px;
}
.dialog-wrapper .dialog-inner .dialog-close:hover {
  outline: 0px none;
}
/*Create manifests Dialog*/

/*manifests section*/
.manifests-detail-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.manifests-detail-section .manifests-section {
  display: flex;
  flex-direction: row;
}
.manifests-detail-section .button-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.manifests-detail-section .button-title button {
  cursor: pointer;
  width: 100%;
  justify-content: center;
  border: 0px none;
  padding: 10px;
  font-family: latomedium;
  font-size: 13px;
  color: #fff;
  background-color: #666666;
}
.manifests-detail-section .manifests-info-table {
  display: flex;
  flex: 1 1 auto;
}
.manifests-detail-section .manifests-info-table table {
  min-width: 100%;
  border-collapse: collapse;
}
.manifests-detail-section .manifests-info-table table th {
  padding: 10px 30px;
  text-align: left;
  font-family: latomedium;
  font-size: 13px;
  color: #000000;
  background-color: #ddd;
  font-weight: normal;
}
.manifests-detail-section .manifests-info-table table td {
  padding: 10px 30px;
  font-family: lato;
  font-size: 12px;
  color: #666666;
}
.manifests-detail-section .manifests-info-table table td a.accordian-arrow {
  margin-right: 5px;
}
.manifests-detail-section .manifests-info-table table tr.expand-row td {
  padding: 10px 30px;
}
.manifests-detail-section
  .manifests-info-table
  table
  tr.expand-row
  .shipmentDetails
  table
  td {
  padding: 10px 0px;
}
.manifests-detail-section
  .manifests-info-table
  table
  tr.expand-row
  .packageItems
  table
  td {
  padding: 10px 30px;
}
.manifests-detail-section .manifests-info-table table tr.expand-row {
  display: none;
}
.manifests-detail-section .manifests-info-table table tr.expand-row.row-active {
  display: table-row;
}

.manifests-detail-section .manifests-information {
  display: flex;
  flex-direction: column;
  border: 1px solid #dadada;
}
.manifests-detail-section .manifests-information .title {
  display: flex;
  padding: 10px 30px;
  font-family: latomedium;
  font-size: 13px;
  color: #000000;
  background-color: #fbfbfb;
}
.manifests-detail-section .manifests-information table {
  min-width: 100%;
  border-collapse: collapse;
}
.manifests-detail-section .manifests-information table .title {
  background-color: transparent;
  padding: 10px 30px;
  text-align: left;
  font-family: lato;
  font-size: 12px;
  color: #666666;
  font-weight: normal;
}
.manifests-detail-section .manifests-information table .value {
  padding: 10px 30px;
  font-family: lato;
  font-size: 12px;
  color: #000000;
}
.manifests-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 220000;
  top: 0;
  bottom: 36px;
  right: 0;
  left: 0;
}

.manifests-add-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
}
.manifests-add-section .section-wrapper {
  padding: 26px;
  display: flex;
}
.manifests-add-section .scan-section {
  padding: 0px;
  width: 20%;
}
.manifests-add-section .scan-section input[type="text"] {
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 10px;
  background-position: right center;
  background-image: url(../../assets/images/edi-scan-enter.png);
  background-repeat: no-repeat;
  border: 1px solid #dedede;
  font-family: latomedium;
  font-size: 13px;
  color: #333;
}
.manifests-add-section .scan-section input[type="text"]:focus {
  outline: 0px none;
}
.manifests-add-section .scan-detail-section {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.manifests-add-section .scan-detail-section .scan-detail {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.manifests-add-section .scan-detail-section .scan-detail .heading-title {
  font-family: lato;
  font-size: 12px;
  color: #333333;
  text-transform: uppercase;
}
.manifests-add-section .scan-detail-section .scan-detail .heading-value {
  font-family: latomedium;
  font-size: 34px;
  color: #333333;
  text-transform: uppercase;
  margin-left: 50px;
}
.manifests-add-section .scan-detail-section .recent-history {
  display: flex;
  flex-shrink: 0;
  margin-bottom: 10px;
}
.manifests-add-section .scan-detail-section .recent-history a {
  font-family: latomedium;
  font-size: 11px;
  color: #018ee1;
  text-decoration: none;
}
.manifests-add-section .scan-section .scan-table {
  display: flex;
  margin-top: 20px;
}
.manifests-add-section .scan-section .scan-table table {
  width: 100%;
  border-collapse: collapse;
}
.manifests-add-section .scan-section .scan-table table td {
  padding: 10px 0;
}
.manifests-add-section .scan-section .scan-table table .title-heading {
  font-family: lato;
  font-size: 12px;
  color: #000000;
  text-transform: uppercase;
}
.manifests-add-section .scan-section .scan-table table .title-value {
  font-family: lato;
  font-size: 12px;
  color: #666666;
  text-transform: uppercase;
}
.manifests-add-section .scan-table-detail-section {
  overflow-x: hidden;
  background-color: #f0f0f0;
  display: flex;
  padding-top: 40px;
  text-align: left;
  position: relative;
  width: 53%;
  margin: 0 7%;
  overflow-y: auto;
}
.manifests-add-section .scan-table-detail {
  display: flex;
  flex: 1;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #f0f0f0;
}
.manifests-add-section .scan-table-detail table {
  min-width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  display: table;
}
.manifests-add-section .scan-table-detail table th {
  height: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.manifests-add-section .scan-table-detail table th span {
  position: absolute;
  top: 5px;
  width: 100%;
  font-family: latomedium;
  padding: 7px 20px;
  text-align: left;
  font-size: 13px;
  color: #333;
  text-transform: uppercase;
  font-weight: normal;
  background-color: #f0f0f0;
}
.manifests-add-section .scan-table-detail table td {
  font-family: lato;
  font-size: 12px;
  color: #666666;
  text-transform: uppercase;
  padding: 7px 20px;
  text-align: left;
}
.manifests-add-section .scan-detail-section .submit-btn {
  display: flex;
  align-items: flex-end;
}
.manifests-add-section .scan-detail-section .submit-btn button {
  background-color: transparent;
  cursor: pointer;
  padding: 0 25px;
  width: auto;
  height: auto;
  border: 2px solid #000000;
  line-height: 30px;
  font-family: gothambold;
  font-size: 10px;
  color: #000000;
  text-transform: uppercase;
}
.manifests-add-section .scan-detail-section .submit-btn button:hover {
  background-color: #000;
  color: #fff;
}
.manifests-add-section .scan-detail-section .recent-history-toggle {
  height: 199px;
}
/*manifests section*/

/*Return Label*/
.return-label-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}
.edi-page-wrapper
  .edi-page-container
  .edi-main
  .edi-main-container.return-label-container {
  overflow: hidden;
}
.edi-main-container.return-label-container .return-label-heading-title {
  flex-shrink: 0;
  padding: 20px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  font-family: gothammedium;
  color: #333;
  background-color: #f0f0f0;
  font-size: 13px;
}
.return-label-wrapper .scan-wrapper {
  flex-direction: column;
  display: flex;
  padding: 50px;
  flex-shrink: 0;
}
.return-label-wrapper .scan-section {
  align-items: flex-start;
  flex-direction: row;
  display: flex;
  max-width: none;
}
.return-label-wrapper .scan-section .scan-block {
  flex-direction: row;
  display: flex;
  width: 30%;
  margin-right: 1%;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 50px;
  margin-bottom: 50px;
}
.return-label-wrapper .scan-section .scan-block input[type="text"] {
  width: 100%;
  max-width: 348px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 10px;
  background-position: right center;
  background-image: url(../../assets/images/edi-scan-enter.png);
  background-repeat: no-repeat;
  border: 1px solid #dedede;
  font-family: latomedium;
  font-size: 13px;
  color: #333;
}
.return-label-wrapper .scan-section .scan-block input[type="text"]:focus {
  outline: 0px none;
}
.return-label-wrapper .scan-section .scan-block label {
  font-family: lato;
  font-size: 13px;
  color: #000000;
  margin-right: 20px;
}
.return-label-wrapper .scan-details {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.return-label-wrapper .scan-details .barcode {
  text-align: center;
}
.return-label-wrapper .scan-details .header-section {
  flex-direction: row;
  display: flex;
  align-items: center;
}
.return-label-wrapper .scan-details .header-section .logo {
  flex: 1 1 auto;
  width: 223px;
  height: 49px;
}
.return-label-wrapper .website-name {
  font-family: latobold;
  font-size: 13px;
  color: #333333;
  margin: 10px 0 10px;
}
.return-label-wrapper .product-details {
  display: flex;
  align-items: center;
}
.return-label-wrapper .product-details .product-detail-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.return-label-wrapper .product-details .product-thumb {
  margin-right: 10px;
}
.return-label-wrapper .product-details .product-thumb img {
  max-width: 100%;
  border: 0px solid #f0f0f0;
  max-height: 186px;
  max-width: 143px;
}
.return-label-wrapper .product-details .product-table {
  border: 1px solid #d1d1d1;
  flex: 1;
  padding: 15px;
}
.return-label-wrapper .product-details table {
  width: 100%;
}
.return-label-wrapper .product-details table td {
  font-size: 13px;
  color: #000000;
  font-family: lato;
}
.return-label-wrapper .product-details table td.title {
  font-size: 13px;
  color: #666;
  font-family: lato;
}
.return-label-wrapper .product-details table td {
  padding: 10px 0;
}
.return-label-wrapper .return-label-footer {
  font-size: 13px;
  color: #000000;
  font-family: lato;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.return-label-wrapper .return-label-footer div {
  margin-top: 10px;
}
.return-label-wrapper .return-label-footer div:last-child {
  font-size: 13px;
  color: #ed1b24;
  font-family: lato;
}
.return-label-wrapper .error-section {
  display: flex;
  flex-direction: column;
}
.return-label-wrapper .error-section label {
  padding-left: 27px;
  background-image: url(../../assets/images/edi-error.png);
  background-repeat: no-repeat;
  font-size: 15px;
  color: #ea333e;
  font-family: lato;
  font-style: italic;
}
.return-label-wrapper .error-section ul {
  margin-left: 27px;
  font-size: 15px;
  color: #ea333e;
  font-family: lato;
  flex-direction: column;
  display: flex;
}
.return-label-wrapper .error-section ul li {
  font-size: 12px;
  color: #666;
  font-family: lato;
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 12px;
}
.return-label-wrapper .print-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.return-label-wrapper .print-section label {
  margin-right: 10px;
  font-size: 13px;
  color: #333333;
  font-family: latobold;
}
/*Return Label*/

/*Label For Apparel*/
.return-label-wrapper .product-detail-wrapper .apparel-details {
  display: flex;
  flex-direction: column;
}
.return-label-wrapper .product-detail-wrapper .apparel-details {
  margin-bottom: 5px;
}
.return-label-wrapper .product-detail-wrapper .apparel-details {
  padding: 0px 0px 0 0px;
}
.return-label-wrapper .product-detail-wrapper .apparel-details table {
  width: 100%;
}
.return-label-wrapper .product-detail-wrapper .apparel-details table td.title {
  font-weight: bold;
}
.return-label-wrapper .product-detail-wrapper .apparel-details table td {
  padding: 5px 0 0;
  font-size: 13px;
  color: #000000;
  font-family: lato;
}
.return-label-wrapper .product-detail-wrapper .apparel-footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0px 0px 0 0px;
}
.return-label-wrapper .product-detail-wrapper .apparel-footer:last-child {
  margin-bottom: 0;
}
.return-label-wrapper .product-detail-wrapper .apparel-footer .title {
  font-size: 13px;
  color: #000000;
  font-family: lato;
  font-weight: bold;
}
.return-label-wrapper .product-detail-wrapper .apparel-footer .value {
  font-size: 13px;
  color: #000000;
  font-family: lato;
}
/*Label For Apparel*/

/*Payment Pages*/
.edi-page-wrapper
  .edi-page-container
  .edi-main
  .edi-main-container.payment-container {
  background-color: #eaeaea;
  overflow-y: unset;
}
.edi-main-container.payment-container .payment-heading-title {
  flex-shrink: 0;
  padding: 20px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  font-family: gothammedium;
  color: #333;
  background-color: #f7f7f7;
  font-size: 13px;
}
.edi-main-container.payment-container .payment-heading-title span {
  flex: 1 1 auto;
}
.edi-main-container.payment-container .payment-heading-title a {
  font-family: lato;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #47a0fb;
  text-decoration: none;
}
.edi-main-container.payment-container .payment-heading-title a .mat-icon {
  margin-left: 5px;
}
.edi-main-container.payment-container .summary-section {
  width: 100%;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  overflow-y: auto;
}
.edi-main-container.payment-container
  .summary-section
  .account-summary-details {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.edi-main-container.payment-container
  .summary-section
  .account-summary-sidebar {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
}
.edi-main-container.payment-container .summary-section .summary-card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 20px;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card:first-child {
  margin-top: 0;
}
.edi-main-container.payment-container .summary-section .summary-card ul {
  display: flex;
}
.edi-main-container.payment-container .summary-section .summary-card ul li {
  min-height: 100px;
  width: 47%;
  padding: 16px 20px;
  margin: 0px 0px 0 0px;
  display: flex;
  background-color: #ffffff;
  border-radius: 2px;
  vertical-align: top;
  box-shadow: 0 -1px 3px 0 rgba(208, 208, 208, 0.5);
  flex-direction: column;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-header
  h4 {
  display: flex;
  align-items: center;
  color: #000000;
  margin: 2px 0 6px;
  font-family: lato;
  font-size: 17px;
  font-weight: normal;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-header
  h4
  span {
  flex: 1 1 auto;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-header
  h4
  a {
  font-family: lato;
  justify-content: flex-end;
  font-size: 13px;
  color: #47a0fb;
  text-decoration: none;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-header
  h4
  a:hover {
  text-decoration: underline;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-header
  .desc-date-block {
  display: flex;
  width: 100%;
  align-items: center;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-header
  .desc-date-block
  .desc-block {
  flex: 1 1 auto;
  min-height: 34px;
  font-family: Arial;
  color: #909090;
  font-size: 12px;
  margin: 5px 0 0;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-header
  .desc-date-block
  .date-block {
  white-space: nowrap;
  margin: 0px 0 0;
  margin-left: 15px;
  padding: 8px 5px;
  background-color: #fcf8e3;
  border: solid 1px #faf2cc;
  border-radius: 3px;
  font-size: 13px;
  color: #f86d72;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li:last-child {
  margin-left: 20px;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block {
  min-height: 79px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-body {
  position: relative;
  color: #3f3f3f;
  font-family: lato;
  font-size: 16px;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-body
  .inner-card-scroll {
  width: 100%;
  height: 83px;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-body
  .inner-card-scroll
  .card-row {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 13px;
  margin-top: 13px;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-body
  .inner-card-scroll
  .card-row
  .card-row-title {
  flex: 1 1 auto;
  color: #000000;
  font-family: lato;
  font-size: 15px;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-body
  .inner-card-scroll
  .card-row
  .card-row-title
  .label-info {
  margin-left: 5px;
  color: #909090;
  font-size: 12px;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-body
  .inner-card-scroll
  .card-row
  .sub-total {
  color: #0871d5;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-footer {
  border-top: 1px solid #ccc;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  margin-top: 13px;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-footer
  .card-row {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 13px;
  margin-top: 13px;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-footer
  .card-row-title {
  flex: 1 1 auto;
  color: #000000;
  font-family: lato;
  font-size: 15px;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .card-footer
  .sub-total {
  color: #000000;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .card-inner-block
  .summaryErrorNote {
  font-size: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .report-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .report-links
  a {
  font-family: lato;
  font-size: 13px;
  color: #47a0fb;
  text-decoration: none;
}
.edi-main-container.payment-container
  .summary-section
  .summary-card
  ul
  li
  .report-links
  a:hover {
  text-decoration: underline;
}

.edi-main-container.payment-container .account-summary-sidebar .ref-block {
  margin: 0px 0 0;
  padding: 12px 21px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 -1px 3px 0 rgba(208, 208, 208, 0.5);
}
.edi-main-container.payment-container
  .account-summary-sidebar
  .ref-block
  .block-title {
  margin-bottom: 10px;
  font-size: 15px;
  color: #3f3f3f;
  display: flex;
  flex-direction: row;
}
.edi-main-container.payment-container
  .account-summary-sidebar
  .ref-block
  .block-title
  span {
  flex: 1 1 auto;
}
.edi-main-container.payment-container
  .account-summary-sidebar
  .ref-block
  .block-title
  .new-item {
  padding: 4px 12px 2px;
  border-radius: 12px;
  background: #f7cb0e;
  color: #ffffff;
  font-size: 12px;
  line-height: 1.2;
}
.edi-main-container.payment-container .account-summary-sidebar .ref-block ul {
  display: flex;
  flex-direction: column;
}
.edi-main-container.payment-container
  .account-summary-sidebar
  .ref-block
  ul
  li {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-top: 1px solid #eeeeee;
}
.edi-main-container.payment-container
  .account-summary-sidebar
  .ref-block
  ul
  li
  a {
  font-family: lato;
  justify-content: flex-end;
  font-size: 13px;
  color: #47a0fb;
  text-decoration: none;
}
.edi-main-container.payment-container
  .account-summary-sidebar
  .ref-block
  ul
  li
  a:hover {
  text-decoration: underline;
}
.edi-main-container.payment-container
  .account-summary-sidebar
  .ref-block
  ul
  li
  .ref-desc {
  color: #717171;
  font-size: 12px;
  line-height: 1.38;
  margin-top: 10px;
}
.edi-main-container.payment-container .search-by-section {
  display: flex;
  align-items: center;
}
.edi-main-container.payment-container .search-by-section .or-separator {
  margin: 0 15px;
}
.edi-main-container.payment-container .previous-payments-sub-header {
  display: flex;
  padding: 20px;
  border-bottom: solid 1px #cccccc;
  background-color: #fff;
}
.edi-main-container.payment-container
  .previous-payments-sub-header
  .month-tabs {
  flex: 1 1 auto;
}
.edi-main-container.payment-container .previous-payments-sub-header ul {
  display: flex;
}
.edi-main-container.payment-container .previous-payments-sub-header ul li {
  display: flex;
}
.edi-main-container.payment-container .previous-payments-sub-header ul li a {
  border-bottom: 2px solid transparent;
  font-family: lato;
  font-size: 13px;
  color: #666666;
  padding: 10px;
  text-decoration: none;
}
.edi-main-container.payment-container
  .previous-payments-sub-header
  ul
  li
  a:hover {
  text-decoration: none;
  border-bottom: 2px solid #006699;
  color: #006699;
}
.edi-main-container.payment-container
  .previous-payments-sub-header
  ul
  li
  a.selected {
  border-bottom: 2px solid #006699;
  color: #006699;
  text-decoration: none;
}
.edi-main-container.payment-container
  .previous-payments-sub-header
  .help
  a
  .mat-icon {
  color: #606060;
}
.edi-main-container.payment-container .previous-payments-table {
  display: flex;
}
.edi-main-container.payment-container .previous-payments-table table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.edi-main-container.payment-container .previous-payments-table table th {
  font-weight: normal;
  padding: 15px 8px 15px 25px;
  color: #4d4d4d;
}
.edi-main-container.payment-container .previous-payments-table table td {
  font-weight: normal;
  padding: 15px 8px 15px 25px;
  color: #4d4d4d;
  font-size: 12px;
}
.edi-main-container.payment-container
  .previous-payments-table
  table
  tr:nth-child(odd) {
  background-color: #f8f8f8;
}
.edi-main-container.payment-container
  .previous-payments-table
  table
  tr:nth-child(even) {
  background-color: #fff;
}
.edi-main-container.payment-container .previous-payments-table table thead {
  background-color: #eaeaea !important;
  color: #6b6b6b;
  font-size: 11px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.edi-main-container.payment-container .previous-payments-table table thead tr {
  background-color: transparent !important;
}
.edi-main-container.payment-container .previous-payments-table table tbody tr {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.edi-main-container.payment-container
  .previous-payments-table
  table
  tbody
  tr:hover {
  border-left: 1px solid #027cd5;
  border-right: 1px solid #027cd5;
}
/*Payment Pages*/

/*Add Product Upload Feed*/
edi-threshold-request {
  padding: 60px;
  padding-top: 0;
}
.upload-feed-wrapper {
  display: flex;
  flex-direction: column;
}
.upload-feed-wrapper .upload-feed-inner {
  display: flex;
  flex-direction: row;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-section {
  height: 100%;
  box-shadow: 0 0 5px #c7c7c7;
  border-radius: 5px;
  max-width: 325px;
  width: 100%;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-block {
  flex-direction: column;
  display: flex;
  padding: 0px 30px;
  height: 180px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d8d8d8;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-block
  .before-upload-section {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-block
  .after-upload-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-block
  .after-upload-section
  .uploaded-title {
  flex: 1 1 auto;
  font-size: 14px;
  color: #666666;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-block
  .after-upload-section
  .delete-symbol {
  border: 0px none;
  display: flex;
  background-color: #e9e8e8;
  padding: 6px;
  font-size: 14px;
  color: #666666;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-status-section
  .heading-title {
  font-family: latomedium;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-block
  .upload-symbol {
  width: 44px;
  height: 44px;
  font-size: 28px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  background-color: #ea3340;
  color: #fff;
  align-items: center;
  margin-right: 10px;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-block
  .upload-title {
  font-family: lato;
  font-size: 18px;
  color: #010101;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-added-block {
  flex-direction: column;
  display: flex;
  padding: 0px 30px;
  height: 180px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d8d8d8;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-added-block
  .aup-loading-spinner {
  min-height: auto;
  height: auto;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-added-block
  .added-symbol
  .mat-icon {
  color: #6ec665;
  font-size: 90px;
  width: 90px;
  height: 90px;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-added-block
  .added-symbol
  .mat-icon.error-icon {
  color: #e92e39;
  font-size: 90px;
  width: 90px;
  height: 90px;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-added-block
  .added-title {
  font-family: latomedium;
  font-size: 20px;
  color: #000;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-footer {
  display: flex;
  flex-direction: column;
  padding: 35px 30px;
  height: 160px;
  align-items: center;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-footer
  button {
  margin-top: 50px;
  font-size: 14px;
  font-family: gothambold;
  background: #fff;
  border: 2px solid #000;
  color: #000000;
  padding: 0 44px;
  line-height: 40px;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-footer
  button:hover {
  background-color: #000;
  color: #fff;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-footer
  .upload-download-msg {
  line-height: 17px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding: 12px 17px;
  font-family: gothambook;
  font-size: 12px;
  color: #29871f;
  background-color: #d9ffd5;
  border-left: 8px solid #52a948;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-footer
  .upload-process-msg {
  line-height: 17px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding: 12px 17px;
  font-family: gothambook;
  font-size: 12px;
  color: #cc7f05;
  background-color: #f0d6ac;
  border-left: 8px solid #efa838;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-section
  .upload-feed-footer
  .upload-error-msg {
  line-height: 17px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding: 12px 17px;
  font-family: gothambook;
  font-size: 12px;
  color: #960009;
  background-color: #f4b6b9;
  border-left: 8px solid #ff000f;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-status-section {
  width: 100%;
  margin-left: 60px;
}
.upload-feed-wrapper .upload-feed-inner .feed-fixed-wrapper {
  margin: 12px 0 0;
  padding: 35px 20px 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow: auto;
}
.upload-feed-wrapper .upload-feed-inner .feed-main-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  min-width: fit-content;
  position: relative;
  display: block;
  position: relative;
  background-color: #f0f0f0;
  padding: 37px 0 0;
}
.upload-feed-wrapper .upload-feed-inner .feed-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  min-width: fit-content;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-table {
  width: 100%;
  margin-top: 0;
  display: block;
  max-height: 300px;
  min-height: 300px;
  background-color: #fff;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-table table {
  width: 100%;
  border-collapse: collapse;
  display: table;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-table table tbody {
  background-color: #fff;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-table table th {
  box-sizing: border-box;
  padding: 0px 14px;
  height: 0;
  background-color: #f0f0f0;
  font-weight: normal;
  text-align: left;
  font-family: latomedium;
  font-size: 13px;
  color: #333333;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-table table th {
  white-space: nowrap;
  text-align: left;
  display: table-cell;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-table table th span {
  position: absolute;
  top: 10px;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-table table tr {
  border-top: 1px solid #f0f0f0;
}
.upload-feed-wrapper .upload-feed-inner .feed-upload-table table td {
  text-transform: capitalize;
  box-sizing: border-box;
  padding: 14px 14px;
  font-family: lato;
  font-size: 12px;
  color: #666666;
  text-align: left;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-table
  table
  td.feed-status
  .status-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-table
  table
  td.feed-status
  .status-block
  .loading {
  margin-right: 20px;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-table
  table
  td.feed-status
  .status {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  display: flex;
  margin-right: 5px;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-table
  table
  td.feed-status
  .status
  .mat-icon {
  font-size: 17px;
  height: 17px;
  width: 17px;
  margin-left: 5px;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-table
  table
  td.feed-status
  .status
  span:first-child {
  padding: 5px 10px;
  font-size: 12px;
  color: #7ab057;
  display: flex;
  align-items: center;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-table
  table
  td.feed-status
  .status
  span:last-child {
  padding: 5px 10px;
  font-size: 12px;
  color: #ee4143;
  border-left: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-table
  table
  td.feed-status
  .download-link {
  display: flex;
  text-decoration: none;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-table
  table
  td.feed-status
  .download-link
  .mat-icon {
  color: #5095e3;
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.upload-feed-wrapper
  .upload-feed-inner
  .feed-upload-table
  table
  td.feed-status
  .refresh-button {
  border: 0px none;
  padding: 7px 14px;
  letter-spacing: 1px;
  background-color: #333;
  display: flex;
  font-family: gothammedium;
  font-size: 9px;
  color: #ffffff;
  text-transform: uppercase;
}
/*Add Product Upload Feed*/

ul.top-title {
  /* display: none; */
  visibility: hidden;
}
.top-title a {
  color: rgba(68, 135, 225, 1);
  text-decoration: none;
}
ul.edi-filterbar-links.ng-star-inserted {
  margin-top: -10px;
}
/*--------------*/
.list-table tr th:nth-child(1) {
  width: 55px;
}
.list-table tr th:nth-child(2) {
  width: 200px;
}
table.box-section {
  border: 1px solid #ccc;
}
i.gift-icon {
  margin-left: 10px;
  background-image: url(../../assets/images/edi-gift.png);
  background-repeat: no-repeat;
  width: 18px;
  height: 16px;
  display: inline-flex;
}
.edi-export-heading {
  padding: 15px 20px;
  background: #f1f1f1;
  font: bold 15px calibri;
  color: #666;
}
.edi-export-select-type-section {
  padding: 30px 0;
  margin: 0px 30px;
  border-bottom: 1px solid #ddd;
}
.edi-export-select-type-section label.ab > span {
  font-weight: bold;
  padding: 1px 12px;
  margin-right: 20px;
}
section.check-box-section {
  padding: 15px;
  display: inline-block;
}
section.check-box-section .mat-checkbox label {
  vertical-align: middle;
  font-family: lato;
  color: #666;
  font-size: 13px;
  margin-bottom: 10px;
  margin-right: 50px;
}
.upload-button .aup-black-button {
  border: 2px solid #000;
  font-family: gothambold;
  font-size: 10px;
  text-transform: uppercase;
  padding: 10px 40px;
}
.upload-button .aup-black-button:hover {
  background: #000;
  color: #fff;
}
.scroll-section {
  width: 150px;
  height: 79px;
  border: 1px solid #ddd;
  display: flex;
  overflow-y: scroll;
}
ul.brands-list {
  width: 100%;
}
ul.brands-list li {
  width: calc(100% - 20px);
  padding: 2px 10px;
}
ul.brands-list li:hover {
  background: #369;
  color: #fff;
}
/* ::-webkit-scrollbar {
    width: 0.5em;
    height: 100%;
}
::-webkit-scrollbar-button {
    background: #f1f1f1
}
::-webkit-scrollbar-track-piece {
    background: #f1f1f1
}
::-webkit-scrollbar-thumb {
    background: #999
} */
.edi-sidebar-links li.import a i {
  background-position: -74px 0;
}
.edi-sidebar-links li.export a i {
  background-position: -102px 0;
}
.edi-sidebar-links li.import a:hover i {
  background-position: -74px -27px;
}
.edi-sidebar-links li.export a:hover i {
  background-position: -102px -27px;
}
.edi-sidebar-links li.import a.active i {
  background-position: -74px -27px;
}
.edi-sidebar-links li.export a.active i {
  background-position: -102px -27px;
}
.auth0-lock-tabs-container {
  display: none;
}
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: 32px !important;
}
.auth0-lock-close-button {
  display: none;
}
/* .auth0-lock-alternative{
    display: none;
} */
.auth0-lock-badge-bottom {
  display: none;
}
#manifest_status_pop ul {
  background: #fff;
  width: 200px;
  margin-top: 0px;
  padding: 0px 0;
}
#manifest_status_pop ul li {
  padding: 10px;
}
#manifest_status_pop ul li:hover,
#manifest_status_pop ul li.active {
  background: #369;
  color: #fff;
}
.date-range {
  display: flex;
  padding-top: 0px;
}
span.active {
  background: #999 !important;
}
.select-import-type,
.select-channel {
  width: 250px;
  margin-right: 50px;
}
.import-feed-select-section {
  padding-bottom: 40px;
}
table tr td strong {
  padding-left: 10px;
}
.top-export-menu img {
  width: 26px;
  margin-right: 20px;
  border: 2px solid #369;
}
.export-job-box {
  position: absolute;
  right: 112px;
  padding: 10px;
  z-index: 999;
  background: #999;
  top: 60px;
  padding-bottom: 0px;
  max-height: 240px;
  overflow-y: scroll;
  box-shadow: 0px 2px 5px #666;
}
nav ul li.import-export-link .importexport_dd .list-block ul {
  margin: 0;
  padding: 0;
}
.export-job-box li.ng-star-inserted {
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  width: 300px;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
}
.export-job-box li.ng-star-inserted:hover {
  box-shadow: 0px 2px 2px #666;
}
ul li .block-title {
  font-size: 12px;
  color: #333;
  margin-bottom: 2px;
}
ul li .block-date {
  color: #999;
  font-size: 10px;
}
.list-block ul li .block {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
ul.instruction li {
  font-size: 19px;
  line-height: 40px;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
img.export-btn {
  border: none;
  width: 15px !important;
  padding: 5px;
  margin: 0;
  cursor: pointer;
}
.gift-label-print {
  border: 2px solid #000;
  background: #fff;
  padding: 3px 15px;
  cursor: pointer;
}
.gift-label-print:hover {
  background: #000;
  color: #fff;
}

/* .table-pending-rejected table {
    background: #000;
}
.table-pending-rejected table tr td {
    background: #fff;
    padding: 5px 15px;
}
.table-pending-rejected table tr th {
    background: #333;
    padding: 5px 15px;
    color: #fff;
    width: 150px;
} */
/* .date-picker-cover {
        border: 1px solid #999;
        padding-left: 20px;
        margin: 15px;
        width: 222px;
    }
    .date-picker-cover .mat-form-field-underline{ display: none; }
    .date-picker-cover .mat-form-field-wrapper{ padding-bottom: 10px; } */
.no-entry-button {
  width: 150px;
  height: 30px;
  background: #fff;
  border: 1px solid red;
  border-radius: 3px;
  margin: 3px;
  font: bold 15px calibri;
  box-shadow: 0px 0.5px 1px #f00;
}
.no-entry-button span {
  color: #369;
  margin-left: 10px;
}
.dashboard-list {
  overflow: hidden;
  width: 100%;
  margin-top: 15px;
  border-bottom: 1px solid #000;
  margin-bottom: 15px;
}
.dashboard-container {
  padding: 20px;
}
.dashboard-list > a {
  text-align: right;
  float: right;
  font: 13px calibri;
}
.dashboard-list > a:hover {
  text-decoration: none;
}
table.dashboard-table {
  width: 100%;
  padding: 0;
  border: none;
  margin: 10px 0;
  float: left;
}
.dashboard-table tr th {
  background: #eee;
  padding: 8px 10px;
  text-align: center;
  color: #000;
  font-size: 12px;
}
.dashboard-table tr td {
  background: #ddd;
  padding: 8px 10px;
  text-align: center;
  color: #369;
  font-size: 12px;
}
.sub-heading {
  margin: 0;
  padding: 0;
  font: bold 20px calibri;
  color: #369;
}
.bottom-menu {
  position: absolute;
  bottom: 0;
  font-size: 13px;
  width: 100%;
}
edi-sidebar {
  position: relative;
  height: 100%;
}
.cdk-overlay-pane {
  max-width: 100% !important;
}
.printer-setting table {
  width: 100%;
  margin: 0;
  padding: 0;
}
/* .printer-setting table > tr td {
        background: #666;
        margin: 0;
        padding:5px ;
    } */
.mat-dialog-container {
  padding: 0 30px !important;
}
table.dashboard-table a {
  cursor: pointer;
}
table.dashboard-table a:hover {
  text-decoration: underline;
}
select.select-list {
  padding: 5px 20px;
  font-size: 13px;
  margin: 6px 0 40px 0;
  width: 450px;
  font: 20px calibri;
}
.printer-setting label {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-align: left;
  width: 300px;
  color: #666;
}
.container-box {
  width: 450px;
  text-align: left;
  margin-top: 100px;
}
h3.sub-heading {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
  margin-left: 30px;
}
h3.child-heading {
  margin: 0;
  text-align: left;
  font-size: 13px;
  color: #999;
  margin-left: 30px;
}
edi-shipment-container .loader {
  position: absolute;
  background: #fff;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0.7;
  padding-top: 50px;
}
.manifest-table {
  margin: 15px;
}
.manifest-table th {
  background: #666;
  color: #fff;
  padding: 5px 10px;
  text-align: left;
}
.manifest-table tr:nth-child(2n + 1) td {
  background: #f1f1f1;
  color: #000;
  padding: 5px 10px;
  text-align: left;
}
.manifest-table tr:nth-child(2n) td {
  background: #ddd;
  color: #000;
  padding: 5px 10px;
  text-align: left;
}
select#select_type {
  padding: 7px;
  display: flex;
  width: 130px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}
.row {
  width: 100%;
  float: left;
}
.row .col-sm-12 {
  padding: 30px;
}
ul.instruction {
  padding: 10px;
  font: 15px calibri;
}
ul.instruction li {
  list-style-type: square !important;
}
.top-export-menu span {
  font-size: 15px;
  position: relative;
  top: -11px;
  right: 15px;
  color: #359;
  font-weight: bold;
  border-bottom: 1px solid #369;
}

ul.nested-menu {
  display: flex;
  align-items: center;
  background: #448ee1;
  padding: 0 15px;
  margin: -30px;
  border-radius: 0;
  margin-bottom: 40px;
  padding-top: 25px;
}
ul.nested-menu li {
  /* width: 50%; */
  text-align: center;
  margin: 0 15px 0 0;
}
ul.nested-menu li a.active {
  background: #fff;
  color: #2f7cd4;
}
ul.nested-menu li a {
  display: block;
  padding: 12px 35px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px 6px 0 0;
  font-weight: bold;
  text-decoration: none;
}
ul.nested-menu li a:hover {
  outline: 0px none;
  background: #ffffff;
  color: #448ee1;
}

.account_setting li {
  font-size: 18px;
  line-height: 30px;
  text-transform: capitalize;
  letter-spacing: 0;
  color: #2681e6;
  border-bottom: 1px solid #ddd;
}
.account_setting li a {
  padding: 5px 5px 0px 0;
  display: block;
  font-family: latomedium;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #2681e6;
}
.account_setting li:last-child {
  border: 0;
}
.account_setting li a b {
  margin-right: 10px;
}
.account_setting li a:hover {
  color: #0067da;
}
.account_setting > h2 {
  border-bottom: 1px solid #ddd;
  padding: 10px 25px 15px 0px;
  font-size: 18px;
  font-family: latomedium;
  color: #000;
}
.account_setting ul {
  /* width: 30%; */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 3px 15px;
  margin: 25px 0;
}
.account_setting li p {
  color: #666;
  font-size: 14px;
  margin: 0;
  padding: 0 0 0 25px;
  line-height: 12px;
  margin-bottom: 15px;
}
.import_block {
  display: flex;
  align-items: flex-start;
}
.account_setting {
  width: 40%;
  margin: 0 20px;
}
ul.nested-menu.edimanifests_menu {
  margin: -40px -20px 25px;
  display: -webkit-box;
}

.manifests-section
  .manifests-table
  table
  .thead:hover
  .manifest-code-search-box {
  display: block;
}
#manifest_status_pop span.close {
  display: none;
}



.matloader .mat-progress-spinner circle,
.matloader .mat-spinner circle {
  stroke: #000000;
}
.matloader {
  position: absolute;
  left: 185px;
  right: 0;
  text-align: center;
  z-index: 99;
  width: 150px;
  top: 0px;
  margin: auto;
  background: #fff;
  padding: 0px 20px;
  box-shadow: 0px 0px 6px #0000003b;
  bottom: 0;
  height: 45px;
  line-height: 45px;
  margin: auto;
}
.matloader mat-spinner{
  top:10px;
}
.matloader span {
  position: absolute;
  top: 0;
  left: 65px;
  font-family: latobold;
  color: #000;
  letter-spacing: 0.5px;
}
.body-container-wrapper  .right-container .main-container .recall-Consignment .table-top .mat-tab-label-container .mat-tab-label {
    min-width: 90px;
    height: 40px;
    padding: 0;
}
.recall-Consignment .mat-tab-body-wrapper {
  display: none;
}

.sidebarnav .mat-expansion-panel-header:hover .mat-expansion-indicator:after{
  border-color :#448ee1;
  color: #448ee1
} 

.sidebarnav .mat-expansion-panel-body {
  padding: 0 24px 0;
  position: relative;
  top: -10px;
}
.sidebarnav .mat-expansion-indicator::after {
  top: -8px;
  position: relative;
}

.sidebarnav  span.mat-expansion-indicator {
  position: absolute;
  top: 12px;
  right: 4px;
}
.mat-form-field.mat-focused .mat-form-field-ripple{
  display: none;
}
.search-product .mat-form-field{
  display: block;
}
.input-search .mat-form-field-appearance-legacy .mat-form-field-label {
  padding: 6px 40px;
}

.input-search .mat-form-field-should-float.mat-form-field-appearance-legacy .mat-form-field-label {
  padding: 6px 0px;
}
.datepicker .calendar-left .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
}
/* Pack item pg paginator css */
.num-of-item .mat-paginator-range-label {
  margin: 0;
}
.num-of-item .mat-icon-button{
  width: 30px;
}
.num-of-item .mat-form-field-wrapper .mat-form-field-infix {
  width: 40px !important;
}

/* Bags list popup style */
.tabs-nav mat-radio-group .mat-radio-button {
  color: #999999;
  font-size: 13px;
  margin-right: 20px;
}
.tabs-nav mat-radio-group .mat-radio-button:last-of-type{
  margin-right: 0;
}
.tabs-nav mat-radio-group .mat-radio-button:last-of-type{
  margin-right: 0;
}
.tabs-nav mat-radio-group .mat-radio-button.mat-accent .mat-radio-inner-circle,
.tabs-nav mat-radio-group .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), 
.tabs-nav mat-radio-group .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, 
.tabs-nav mat-radio-group .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
 background: #4584f5; 
}
.tabs-nav mat-radio-group .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4584f5; 
}
.tabs-nav mat-radio-group .mat-radio-outer-circle, .tabs-nav .mat-radio-inner-circle {
  height: 17px;
  width: 17px;
}
.tabs-nav mat-radio-group .mat-radio-checked{
  color: #4584f5; 
}
.tabs-nav .mat-form-field-appearance-legacy .mat-form-field-label{
  color: #4584f5; 
}
.tabs-nav input:-internal-autofill-selected {
  background-color: none !important;
  background-image: none !important;
}
.tabs-nav .mat-form-field-wrapper .mat-select-arrow{
  border-color: #4584f5 !important; 
}
.recall-Consignment .mat-paginator-range-label{
  margin: 0 15px;
}
mat-form-field.mat-form-field .mat-form-field-infix{
  position: relative;
}
mat-form-field.mat-form-field .mat-form-field-infix .tooltip.abs{
  position: absolute;
  top: 5px;
  left: 106px;
  width: 11px;
  z-index: 999;

}
mat-form-field.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .tooltip.abs{
  top: -15px;
  left: 81px;
}
mat-form-field.mat-form-field .mat-form-field-infix .tooltip.abs:hover:after{
  z-index: 999;
  padding: 9px 10px 9px 20px;
}

.example-container.completeConsignment {
  border-bottom: 1px solid #999;
  margin-bottom: 20px;
}
.body-container-wrapper .right-container .main-container .table-top{
  margin-bottom: -2px;
}
.body-container-wrapper .right-container .main-container{
  padding: 0;
}
.body-container-wrapper .right-container .main-container .table.dashboard h2,
.body-container-wrapper .right-container .date-picker-cover,
.table-pending-rejected h2, .table-pending-rejected h2{
  padding: 0 15px;
}
.dashboard-list > a{
  margin-right: 15px;
}
.body-container-wrapper .right-container .main-container .table-top .mat-tab-label-container .mat-tab-label{
  border-top: 0;
  border-left: 0;
}
.table.dashboard:nth-child(2) ul li > span:nth-child(1) {
  width: 20% !important; 
}
.table.dashboard:nth-child(2) ul li span:nth-child(4) {
  width: 30% !important; 
}
.table.dashboard:nth-child(2) ul li span:nth-child(3) {
  width: 18% !important; 
}
.body-container-wrapper .right-container .main-container .inword-popup form{
  max-height: 450px;
  overflow: auto;
}
.table-wrapper.consign-lsit table tr th.cdk-column-consignmentNumber:nth-child(2) div{
  text-align: center;
  justify-content: center;
}
.body-container-wrapper .right-container .main-container table .highlight-green{
  background: green;
}
.body-container-wrapper .right-container .main-container table .highlight-green td,
.body-container-wrapper .right-container .main-container table .highlight-green span{
  color: #fff;
}
.body-container-wrapper .right-container .main-container table .highlight-green b{
  color: #fff !important;
}
.dimension-inpt:empty{
  max-width: 0 !important;
}
.shipment-main .table thead th:nth-child(2){
  width: 22%;
}
.shipment-main .table tbody td b{
   color: #8a8a8a;
   font-weight: 900;
}
.choose-action .mat-form-field-wrapper .mat-select-value-text span{
  color: #111111;
}