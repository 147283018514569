@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
$color-default: #333;
$color-link: #448ee1;
$color-link-hover: #ea333e;

@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin transform($scalex, $scaley) {
  -webkit-transform: translate($scalex, $scaley);
  -ms-transform: translate($scalex, $scaley);
  -o-transform: translate($scalex, $scaley);
  transform: translate($scalex, $scaley);
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition-effect {
  -webkit-transition: all 0.35s ease 0s;
  -moz-transition: all 0.35s ease 0s;
  -o-transition: all 0.35s ease 0s;
  transition: all 0.35s ease 0s;
}

@mixin flex {
  display: -ms-flexbox;
  display: flex;
}

@mixin inline_flex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin justify-center {
  justify-content: center;
  -ms-flex-align: center;
}

@mixin align-center {
  -ms-flex-align: center;
  align-items: center;
}

@mixin topY-50 {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin topX-50 {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
$inputs-list: 'input[type="text"]', 'input[type="number"]', 'input[type="email"]', "select", ".files-box";
.disable-input {
  opacity: 0.5;
  pointer-events: none;
}
// .consignment-rec-container {
//   input:focus,
//   button:focus {
//     border: 2px solid green !important;
//   }
// }
.account_setting li {
  font-size: 18px;
  line-height: 30px;
  text-transform: capitalize;
  letter-spacing: 0;
  color: #2681e6;
  border-bottom: 1px solid #ddd;
  list-style: none;
}

.account_setting li a {
  padding: 5px 5px 0px 0;
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #2681e6;
}

.account_setting li:last-child {
  border: 0;
}

.account_setting li a b {
  margin-right: 10px;
}

.account_setting li a:hover {
  color: #0067da;
}

.account_setting > h2 {
  border-bottom: 1px solid #ddd;
  padding: 10px 25px 15px 0px;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  color: #000;
}

.account_setting ul {
  border-radius: 5px;
  padding: 3px 15px;
  margin: 25px 0;
}

.account_setting li p {
  color: #666;
  font-size: 14px;
  margin: 0;
  padding: 0 0 0 25px;
  line-height: 12px;
  margin-bottom: 15px;
}

.account_setting {
  background: #fff;
  padding: 30px;
}

/*===== Alert Message =======*/
app-web-alert {
  position: fixed;
  top: 35px;
  right: 15px;
  width: 100%;
  max-width: 350px;
  z-index: 99999998;
}

.notify {
  position: relative;
  right: 0;
  top: 15px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  opacity: 1;
  z-index: 99999999;
  border: 1px solid;

  &:last-child {
    margin-bottom: 0;
  }

  &.notify-default {
    background-color: #f0f0f0;
    border: 1px solid #e3e3e3;
  }

  &.notify-success {
    background-color: #77dd77;
    border-color: #63d863;

    .notify-body {
      color: #ffffff;

      * {
        color: #ffffff;
      }
    }

    .notify-action-buttons {
      .notify-square-button {
        &.notify-close-button {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xMC4xMTksOGw0LjU5Ny00LjU5N2MwLjU4Ni0wLjU4NSwwLjU4NS0xLjUzNCwwLTIuMTE5cy0xLjUzMy0wLjU4NS0yLjExOSwwTDgsNS44ODFMMy40MDMsMS4yODQNCgljLTAuNTg1LTAuNTg1LTEuNTM0LTAuNTg1LTIuMTE5LDBjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTlMNS44ODEsOGwtNC41OTgsNC41OThjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTkNCgljMC41ODUsMC41ODQsMS41MzMsMC41ODQsMi4xMTgsMEw4LDEwLjExOGw0LjU5OCw0LjU5OGMwLjU4NSwwLjU4NSwxLjUzMywwLjU4NSwyLjExOSwwYzAuNTg0LTAuNTg1LDAuNTg0LTEuNTMzLDAtMi4xMThMMTAuMTE5LDh6DQoJIi8+DQo8L3N2Zz4NCg==");
        }
      }
    }
  }

  &.notify-error {
    background-color: #d84941;
    border-color: #d43c34;

    .notify-body {
      color: #ffffff;

      * {
        color: #ffffff;
      }
    }

    .notify-action-buttons {
      .notify-square-button {
        &.notify-close-button {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xMC4xMTksOGw0LjU5Ny00LjU5N2MwLjU4Ni0wLjU4NSwwLjU4NS0xLjUzNCwwLTIuMTE5cy0xLjUzMy0wLjU4NS0yLjExOSwwTDgsNS44ODFMMy40MDMsMS4yODQNCgljLTAuNTg1LTAuNTg1LTEuNTM0LTAuNTg1LTIuMTE5LDBjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTlMNS44ODEsOGwtNC41OTgsNC41OThjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTkNCgljMC41ODUsMC41ODQsMS41MzMsMC41ODQsMi4xMTgsMEw4LDEwLjExOGw0LjU5OCw0LjU5OGMwLjU4NSwwLjU4NSwxLjUzMywwLjU4NSwyLjExOSwwYzAuNTg0LTAuNTg1LDAuNTg0LTEuNTMzLDAtMi4xMThMMTAuMTE5LDh6DQoJIi8+DQo8L3N2Zz4NCg==");
        }
      }
    }
  }

  &.notify-info {
    background-color: #89abd2;
    border-color: #779ecb;

    .notify-body {
      color: #ffffff;

      * {
        color: #ffffff;
      }
    }

    .notify-action-buttons {
      .notify-square-button {
        &.notify-close-button {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xMC4xMTksOGw0LjU5Ny00LjU5N2MwLjU4Ni0wLjU4NSwwLjU4NS0xLjUzNCwwLTIuMTE5cy0xLjUzMy0wLjU4NS0yLjExOSwwTDgsNS44ODFMMy40MDMsMS4yODQNCgljLTAuNTg1LTAuNTg1LTEuNTM0LTAuNTg1LTIuMTE5LDBjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTlMNS44ODEsOGwtNC41OTgsNC41OThjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTkNCgljMC41ODUsMC41ODQsMS41MzMsMC41ODQsMi4xMTgsMEw4LDEwLjExOGw0LjU5OCw0LjU5OGMwLjU4NSwwLjU4NSwxLjUzMywwLjU4NSwyLjExOSwwYzAuNTg0LTAuNTg1LDAuNTg0LTEuNTMzLDAtMi4xMThMMTAuMTE5LDh6DQoJIi8+DQo8L3N2Zz4NCg==");
        }
      }
    }
  }

  .notify-body {
    padding: 15px;
    font-size: 13px;
    min-height: 54px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;

    .notification-body-icon {
      float: left;
      width: 38px;
      height: 32px;
      vertical-align: top;
      display: inline-block;
      position: relative;
      text-align: center;
      font-size: 32px;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .notify-body-title {
      display: block;
      font-weight: 700;
      text-transform: capitalize;
    }

    .notify-main-content {
      width: -webkit-calc(100% - 38px);
      width: calc(100% - 38px);
      float: left;
      padding-left: 10px;

      * {
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }

  .notify-action-buttons {
    width: 16px;
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 2px;

    .notify-square-button {
      background-position: center;
      background-size: 8px;
      background-repeat: no-repeat;
      display: block;
      height: 16px;
      width: 16px;

      &.notify-close-button {
        display: block;
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik0xMC4xMTksOGw0LjU5Ny00LjU5N2MwLjU4Ni0wLjU4NSwwLjU4NS0xLjUzNCwwLTIuMTE5cy0xLjUzMy0wLjU4NS0yLjExOSwwTDgsNS44ODFMMy40MDMsMS4yODQNCgljLTAuNTg1LTAuNTg1LTEuNTM0LTAuNTg1LTIuMTE5LDBjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTlMNS44ODEsOGwtNC41OTgsNC41OThjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTkNCgljMC41ODUsMC41ODQsMS41MzMsMC41ODQsMi4xMTgsMEw4LDEwLjExOGw0LjU5OCw0LjU5OGMwLjU4NSwwLjU4NSwxLjUzMywwLjU4NSwyLjExOSwwYzAuNTg0LTAuNTg1LDAuNTg0LTEuNTMzLDAtMi4xMThMMTAuMTE5LDh6DQoJIi8+DQo8L3N2Zz4NCg==");
      }
    }
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  //text-align: center;

  .title {
    margin: 0 0 20px;
  }

  p {
    margin: 0 0 20px;
  }

  .action-controller {
    text-align: right;
  }

  .popup-container {
    background: #fff;
    padding: 40px;
    max-width: 500px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    label {
      display: block;
      color: #5c5c5c;
      margin-bottom: 10px;
    }

    .close-popup {
      background: url("assets/images/x.png") center center no-repeat;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 22px;
      height: 23px;
      content: "";
      cursor: pointer;
      z-index: 10;
    }
  }
}

/*===== Loader ==========*/
body {
  &.loader-active {
    overflow: hidden;
  }
}

.page-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;

  .loader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100px;
    width: 100px;
    margin-top: -50px;
    margin-left: -50px;
    background: url("assets/images/loader.gif") no-repeat center center;
    background-size: 100%;
  }
}
/*===== Loader End ==========*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-family: "Lato", sans-serif;
  margin: 0;
  background-color: #ececec;
  line-height: 1.43;
  font-size: 16px;
  color: $color-default;
}

body {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.4;
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: #007bff;
  text-decoration: none;
  @include transition-effect;
  background-color: transparent;

  &:hover {
    color: #0056b3;
    text-decoration: none;
  }
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

small {
  font-size: 80%;
  font-weight: 400;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

input::-webkit-input-placeholder {
  color: #bbbbbb;
  opacity: 1;
  text-align: inherit;
}

input::-moz-placeholder {
  color: #bbbbbb;
  opacity: 1;
  text-align: inherit;
}

input:-ms-input-placeholder {
  color: #bbbbbb;
  opacity: 1;
  text-align: inherit;
}

input::-ms-input-placeholder {
  color: #bbbbbb;
  opacity: 1;
  text-align: inherit;
}

input::placeholder {
  color: #bbbbbb;
  opacity: 1;
  text-align: inherit;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

button:focus,
a:focus,
input:focus {
  outline: none;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:hover {
    color: #212529;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    opacity: 0.65;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;

  &:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    .active {
      color: #fff;
      background-color: #0062cc;
      border-color: #005cbf;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
      }
    }
  }
}

.btn-filter {
  border: none;
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.5);
  background: #fff;
  color: #666 !important;
}

.btn-transparent {
  border-radius: 0;
  border: 3px solid #333;
  padding: 8px 38px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.text-center {
  text-align: center;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.caret {
  padding-right: 30px;

  &:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border: 4px solid transparent;
    border-top: 5px solid #000;
    border-bottom: 0;
    top: 50%;
    @include topY-50;
    right: 5px;
  }
}

.unlist {
  margin: 0;
  list-style: none;
  padding: 0;
}

ul.ul-dropdown {
  @extend .unlist;

  > li {
    position: relative;
    z-index: 9;

    > a,
    > span {
      cursor: pointer;
      @extend .caret;

      &.active {
        + ul {
          opacity: 1;
          top: 100%;
          visibility: visible;
        }
      }
    }
  }

  ul {
    @extend .unlist;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 120%;
    right: 0;
    @include transition-effect;
    min-width: 170px;
    text-align: right;
    padding: 0;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.24);
    z-index: 9;
    background: #fff;

    li {
      a {
        display: block;
        padding: 5px 15px;
        border-bottom: 1px dashed #ccc;
        color: inherit;

        &:hover {
          color: $color-link;
        }
      }

      &:last-child {
        a {
          border: none;
        }
      }
    }
  }
}

/* Header Styling Start */
header {
  @include clearfix;
  @include flex;
  padding: 0 50px;

  .header-logo {
    width: 177px;
    padding: 18px 0 0;
  }

  .header-container {
    padding: 16px 0;
    width: calc(100% - 177px);

    .header-location-dropdown {
      margin-right: 15px;

      > ul {
        > li {
          > a,
          > span {
            padding-right: 20px;

            &:after {
              height: 6px;
              width: 6px;
              border-width: 1px;
              border-style: none solid solid none;
              border-color: $color-link-hover;
              /* -webkit-transform: rotate(-45deg); */
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(45deg);
              top: calc(50% - 4px);
            }
          }
        }
      }

      ul {
        li {
          a,
          .ul-dropdown-toggle {
            //color: $color-link;
          }
        }
      }
    }

    .header-search {
      .ul-dropdown {
        > li {
          > a,
          > .ul-dropdown-toggle {
            display: block;
            padding: 6px 35px 6px 10px;
            background: #f5f5f5;
            border: 1px solid #cfcfcf;

            &:after {
              right: 13px;
            }
          }
        }
      }

      .header-search-form {
        position: relative;
        width: 317px;

        .header-search-bar {
          display: block;
          border: 1px solid #cfcfcf;
          border-left: 0;
          padding: 6px 10px 6px 40px;
          width: 100%;
          border-radius: 0 4px 4px 0;
        }

        .header-search-btn {
          position: absolute;
          height: 15px;
          width: 15px;
          left: 11px;
          top: 8px;
          background: url(assets/images/search.png) no-repeat center center;
          border: none;
          box-shadow: none;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
        a {
          position: absolute;
          right: 10px;
          top: 8px;
          color: #212529;
          font-weight: 700;
        }
      }
    }

    .header-language {
      margin-left: auto;

      .ul-dropdown {
        > li {
          > span {
            padding-right: 18px;
            display: inline-block;

            &:after {
              right: 0;
            }
          }

          ul {
            right: 5px;
            min-width: 55px;
          }
        }
      }
    }
  }
}

/* Header Styling End */

/* Footer Styling Start */

footer {
  p {
    text-align: center;
    margin: 1.5rem 0;
    color: #999999;
    font-size: 12px;
  }
}

/* Footer Styling End */

/* Body Styling Start */

.body-container-wrapper {
  padding: 10px 50px;
  //@include flex;
  @include clearfix;

  aside.left-sidebar {
    width: 177px;
    float: left;

    .sidebar-nav {
      ul {
        @extend .unlist;

        li {
          display: block;

          a {
            display: block;
            position: relative;
            padding: 10px 10px 10px 50px;
            color: #666666;
            background: transparent;
            @include transition-effect;

            img {
              position: absolute;
              left: 17px;
              top: 50%;
              @include topY-50;
              @include transition-effect;
            }

            &:hover,
            &.active {
              background: $color-link;
              color: #fff;

              img {
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
                -ms-filter: brightness(0) invert(1);
              }
            }
          }
        }
      }
    }
  }

  .right-container {
    width: calc(100% - 177px);
    float: right;

    .top-pane {
      @include clearfix;
      padding-left: 30px;
      padding-top: 6px;

      .breadcrumb-menu {
        float: left;

        ul {
          @extend .unlist;
          @include flex;
          padding-left: 20px;

          li {
            + li {
              &:before {
                content: "/";
                margin: 0 5px;
                display: inline-block;
              }
            }
          }
        }
      }

      .right-menu {
        float: right;

        img[src*="export"] {
          opacity: 0.45;
        }

        ul {
          @extend .unlist;
          @include flex;

          li {
            margin-left: 15px;
          }
        }
      }

      + .main-container {
        margin-top: 7px;
      }
    }

    .main-container {
      margin: 10px 0 0 30px;
      border-radius: 5px;
      box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.25);
      background: #fff;
      height: 700px;
      overflow: auto;
      .table-top {
        display: flex;
        justify-content: space-between;
        .mat-tab-label-container {
          border-right: 1px solid #999;
          .mat-tab-label {
            min-width: 125px;
            border-right: 1px solid #999;

            &:last-child {
              border-right: 0;
            }
          }
        }
      }
      .section-heading {
        background: #f0f0f0;
        padding: 20px;
        position: relative;

        .section-filters {
          position: absolute;
          top: 0;
          right: 0;
          padding: 12px 20px;

          ul {
            @include flex;
            @include align-center;
            @extend .unlist;

            li {
              margin: 0 0 0 20px;

              .calender-wrapper {
                position: relative;
                display: inline-block;
                margin-left: 15px;
                width: 165px;

                .btn-filter {
                  width: 100%;
                  text-align: left;
                }

                .calender-toggle {
                  @extend .caret;
                  // background: #dcdcdc url(assets/images/calender.png) no-repeat
                  //   5px 50%;
                  border-radius: 0 0.25rem 0.25rem 0;
                  height: 100%;
                  width: 50px;
                  position: absolute;
                  right: 0;
                  top: 0;
                  background: #dbdbdb;

                  &:after {
                    border-width: 6px 6px 0;
                    border-top-color: #666;
                  }
                }
              }
            }
          }
        }

        p {
          margin: 0;
        }
      }

      .section-body {
        ol.custom-listing {
          margin: 0;
          padding: 0;
          list-style: none;
          counter-reset: my-custom-counter;

          > li {
            counter-increment: my-custom-counter;
            color: #333;

            &:before {
              content: counter(my-custom-counter) ". ";
              color: inherit;
              margin-right: 20px;
              text-decoration: none;
            }

            &:hover {
              color: $color-link;
            }
          }
        }
      }

      .inbound-section {
        .section-body {
          padding: 25px;

          li {
            margin: 0 0 20px;

            a {
              color: #333;

              &:hover {
                color: $color-link;
                text-decoration: underline;
              }
            }
          }
        }
      }

      .wholesale-section {
        table {
          border: none;
          padding: 17px;

          &.pick-list-detail-table {
            padding: 0;
          }

          th {
            &:before {
              content: "";
              display: inline-block;
              height: 0;
              width: 0;
              border: 7px solid transparent;
              border-top-color: #333333;
              border-bottom: none;
              margin-right: 5px;
              vertical-align: baseline;
            }
          }
          td {
            padding: 27px 0 10px 17px;
          }
        }
        td.row-wrap {
          border: none;
          padding: 0;
          tbody {
            width: 100%;
            display: block;
            tr {
              width: 100%;
              display: table;

              td {
                width: 18%;
                padding-left: 0;
                &:last-child {
                  width: 10%;
                }

                .pick-list-detail-table {
                  padding: 0;
                  td {
                    width: auto;
                  }
                }
              }
            }
          }
          .parent-row {
            td {
              &:first-child a:after {
                content: "";
                display: inline-block;
                height: 0;
                width: 0;
                border: 5px solid transparent;
                border-top-color: #333333;
                border-bottom: none;
                margin-left: 5px;
                vertical-align: middle;
                cursor: pointer;
              }
            }
          }
          .child-row {
            padding: 10px 0 0 15px;
            border-bottom: 1px solid #e3e3e3;
            &:nth-of-type(1) {
              border-bottom: 1px solid #e3e3e3 !important;
            }
            &:last-child {
              border: none;
            }

            td {
              border-bottom: none;
              padding: 10px 0;
            }

            &.padding-zero {
              padding: 0;
            }
          }
        }

        table {
          &.pciklist-table {
            tr {
              th {
                width: 15%;

                &:nth-child(2) {
                  width: 20%;
                }

                &:nth-child(6),
                &:nth-child(7) {
                  width: 10%;
                }
              }
            }

            td {
              &.row-wrap {
                tbody {
                  tr {
                    td {
                      width: 15%;

                      &:nth-child(2) {
                        width: 20%;
                      }

                      &:nth-child(6),
                      &:nth-child(7) {
                        width: 10%;
                      }
                    }
                  }
                }

                .child-row {
                  padding: 10px 0 0 0;
                }
              }
            }
          }
        }
      }

      table {
        width: 100%;
        text-align: left;

        th {
          white-space: nowrap;
        }

        td {
          border-bottom: 1px solid #e3e3e3;
        }
        td.mat-column-consignmentNumber {
          text-align: left;
        }
      }

      .shipping-table {
        width: 100%;
        max-width: 725px;

        .table-detail {
          margin-bottom: 10px;

          .right {
            color: #d92a34;
            font-style: italic;
          }
        }

        table {
          padding: 0 10px 3rem;
          border: 1px solid #e3e3e3;
          border-radius: 3px;

          th {
            border-bottom: 1px solid #e3e3e3;
            padding: 10px 10px 15px;
          }

          td {
            padding: 15px 10px;
            font-size: 12px;
          }
        }
      }

      .shipment-section {
        padding: 15px 20px 20px;

        .shipping-detail {
          table {
            td {
              white-space: nowrap;
              border: none;
              vertical-align: top;
              padding: 0;
              height: 60px;

              .shipping-info-title {
                color: #999999;
                padding-right: 1rem;
                display: inline-block;
              }

              .shipping-info-detail {
                padding-right: 3rem;
                color: #333333;
                font-weight: 700;
                display: inline-block;

                .shipping-info-btn {
                  display: block;

                  span {
                    display: inline-block;
                    border: 1px solid #1e8d1b;
                    color: #1e8d1b;
                    font-size: 12px;
                    padding: 1px 8px;
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }
      }

      table {
        &.white-space-nowrap {
          td {
            span {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.no-data {
  padding: 15px;
}

.no-consignment {
  padding: 100px 0 0;
  text-align: center;
  font-size: 18px;
  color: #999;
  p {
    margin: 15px 0 0;
  }
}

.summary-table .multiselect-dropdown {
  max-width: 243px;
}

.cdk-overlay-pane {
  .mat-datepicker-content {
    margin-top: 5px;
  }
  // width: 600px !important;

  .mat-dialog-container {
    padding: 0;

    .close {
      float: right;
      margin: 25px 18px 13px;
      text-align: center;
      cursor: pointer;
      background: none;
      border: none;
      color: inherit;

      small {
        display: block;
        font-size: 10px;
      }
    }

    .drag-area {
      max-width: 370px;
      margin: 0 auto;
      clear: both;
      border: 1px solid #999999;
      border-radius: 4px;
      overflow: hidden;

      .db-header {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        background: #f0f0f0;
        font-weight: 700;

        .left {
          img {
            margin-right: 10px;
            vertical-align: -1px;
          }
        }
      }

      .db-body {
        min-height: 155px;
        @extend .d-flex;
        @extend .flex-column;
        @extend .justify-content-center;
        @extend .align-items-center;

        > span {
          font-size: 18px;
          letter-spacing: 0.4px;

          &:not(.path-name) {
            color: #e0dfdf;
          }

          &.path-name {
            display: none;
            color: #666666;
            text-decoration: underline;

            a {
              cursor: pointer;
              margin-left: 4px;

              img {
                width: 12px;
              }
            }
          }
        }
      }
    }

    .btn-transparent {
      margin: 2rem 0 1.5rem;
    }

    .example-container {
      height: 400px;
      width: 550px;
      overflow: auto;
    }

    table {
      width: 800px;
    }

    td.mat-column-star {
      width: 20px;
      padding-right: 8px;
    }

    th.mat-column-position,
    td.mat-column-position {
      padding-left: 8px;
    }

    .mat-table-sticky:first-child {
      border-right: 1px solid #e0e0e0;
    }

    .mat-table-sticky:last-child {
      border-left: 1px solid #e0e0e0;
    }
  }
}

.completeConsignment {
  // overflow-x:auto;
  table {
    // min-width: 1400px;

    th {
      // border-color:#fff;
      text-align: center;
      font-weight: 700;
    }
    td {
      text-align: center;
    }
  }
  .funnel-filter {
    position: relative;
  }
  .mat-tab-labels {
    border-bottom: 1px solid #b0b0b0;
  }

  .mat-tab-label-active {
    background: #fff;
    border: none;
    outline: none;
    box-shadow: 0px;
    border-top: 1px solid #b0b0b0;
    border-left: 1px solid #b0b0b0;
    border-right: 1px solid #b0b0b0;
    border-bottom: 0px solid white;
    margin-bottom: 0px;
    padding-bottom: 0px;
    box-shadow: 0px 5px white;
    color: black;
    opacity: 1;
  }

  mat-ink-bar {
    display: none;
  }

  mat-ink-bar {
    display: none;
  }

  mat-tab-header {
    border-color: white;
  }
}
.recall-Consignment .mat-tab-header {
  border-bottom: 0px;
}
.header,
.header-space,
.footer,
.footer-space {
  height: 100px;
}

.header {
  position: fixed;
  top: 0;
}

.footer {
  position: fixed;
  bottom: 0;
}

@media screen {
  :host {
    display: none;
  }
}

.invoice {
  page-break-after: always;
}

.invoice:last-child {
  page-break-after: avoid;
}

@media print {
  app-root > * {
    display: none;
  }

  app-root app-print-layout {
    display: block;
  }
}

// wholesale datepicker  27-2-2019

.date-filter .mat-button-wrapper {
  .mat-datepicker-toggle-default-icon {
    vertical-align: top;
    margin-top: 5px;
  }
}

.section-filters {
  .date-filter {
    display: flex;
    align-items: center;

    .mat-input-element {
      padding: 6px 8px 8px;
      background: #fff;
    }
  }
}

.mat-menu-content .mat-menu-item {
  height: auto;
  line-height: normal;

  &:hover {
    background: transparent;
  }
}

.header-language {
  .mat-button-wrapper {
    &:after {
      position: absolute;
      content: "";
      right: 7px;
      top: 19px;
      border: 5px solid #000;
      border-left: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }

  .mat-icon-button {
    text-align: left;
  }
}

.mat-form-field-wrapper {
  .mat-form-field-underline {
    height: 0 !important;
  }

  .mat-select-value-text span {
    color: #438ee1;
  }

  .mat-form-field-infix {
    width: 130px;
  }

  .mat-select-arrow {
    border: 1px solid #e13b3f;
    width: 6px;
    height: 6px;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    margin: 2px 5px 0;
    display: inline-block;
    vertical-align: top;
  }
}

.mat-select-panel {
  margin-top: 25px;
}

.confirmation-buttons {
  button {
    padding: 10px;
    background: #fff;
    border: 0;
    cursor: pointer;
  }

  .ok-btn {
    background: #2196f3;
    @include border-radius(4px);
    color: #fff;
    margin-left: 40px;
  }
}

/* Body Styling End */

// Pdf Table
.picklist-pdf {
  max-width: 1310px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
  // margin-top: -80px;

  span,
  td {
    color: #000;
    font-size: 18px;
  }

  .picklist-header {
    outline: 2px solid #000;
    margin-bottom: 5px;
    margin-top:-75px;
  }

  .picklist-top-pane {
    //border-bottom: 1px solid #000;
    padding: 5px;
    span {
      font-weight: 400;
      flex: 0 0 33.33%;
    }

  }


  .picklist-second {
    h4 {
      text-align: center;
    }
    h5 {
      display: block;
    }
  }
  .picklist-barcode-wrapper {
    padding: 5px 10px;
    .invoice-qty,
    .picklist-barcode {
      display: inline-block;
      vertical-align: top;
    }
    .picklist-barcode {
      text-align: left;
      svg {
        width: 100%;
        max-width: 150px;
        height: 60px;
      }
    }
  }
  .picklist-body table {
    page-break-inside: auto;
  }
  .picklist-body tr {
    page-break-inside: avoid;
    page-break-after: always;
  }
  .picklist-body thead {
    display: table-header-group;
  }
  .picklist-body tfoot {
    display: table-footer-group;
  }

  table {
    border: 2px solid #000;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 5px;

    th,
    td {
      border: 1px solid #000;
      padding: 10px;
      font-size: 12px;
    }

    tr:nth-child(odd) {
      background: #cccccc;
    }
  }

  .picklist-footer {
    @extend .picklist-barcode-wrapper;
    border: 2px solid #000;
    padding-bottom: 20px;
  }
}

.popup-form {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}
.form {
  max-width: 470px;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  padding: 30px 55px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  &.barcode-form{
      padding: 30px 30px!important;
      top: 0!important;
      bottom: 0!important;
      overflow-y: auto!important;
      transform: translateX(-50%)!important;
  }
  .form-title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 40px;
    clear: both;
    &.form-title-2 {
      margin-bottom: 10px;
    }
  }
  .form-body {
    text-align: center;
  }
  h2 {
    font-size: 21px;
    margin-bottom: 0;
  }
  .red {
    font-size: 25px;
    color: #e31e3a;
  }
  .close-popup {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    background: url(assets/images/close.png) center center no-repeat;
  }
  .input-field {
    margin-bottom: 20px;
    position: relative;
    input {
      width: 100%;
      padding: 4px 0 11px;
      border: 0;
      border-bottom: 1px solid #ccc;
      &:focus {
        outline: 0;
      }
    }
    &.error {
      color: #b13b2d;
    }

    #{$inputs-list} {
      border: none;
      border-bottom: 1px solid #c9c9c9;
      width: 100%;
      outline: none;
      padding: 0 2px;
    }
    &.error {
      #{$inputs-list} {
        border: 1px solid #b13b2d !important;
      }
    }
    .files-box {
      color: #ccc;
      padding-bottom: 12px;
    }
    .mat-chip-list {
      position: relative;
      .mat-chip {
        padding-right: 25px;

        span {
          position: absolute;
          right: 10px;
          top: 5px;
        }
      }
    }

    label {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 3px;
    }
    .optional {
      color: #999;
    }
  }

  .upload-file {
    label {
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        background: url(assets/images/clip.png) center center / 100% 100%;
        position: absolute;
        right: 0;
        bottom: 11px;
      }
    }
    input {
      display: none;
    }
  }
  .btn-wrapper {
    text-align: right;
    .submit-button {
      padding: 7px 23px 7px 64px;
      font-size: 16px;
      text-transform: uppercase;
      background: #fff;
      border: 2px solid #013334;
      position: relative;
      cursor: pointer;
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: "s";
        bottom: 0;
        padding: 7px 15px;
        background: #e9e9e9;
      }
    }
  }
}

.table-wrapper {
  overflow: auto;
  tr {
    .mat-sort-header-button,
    th,
    td {
      color: #000;
      padding-right: 5px;
      padding-left: 5px;
      white-space: normal !important;
    }
    .mat-sort-header-button {
      display: block;
    }
    .status-button {
      color: #fff;
      font-size: 12px;
      border: 0;
      padding: 5px 15px;
      border: 0;
      @include border-radius(4px);
    }
    .mat-raised-button {
      box-shadow: none;
      border: 1px solid #000;
      @include border-radius(0);
      padding: 0 5px;
      font-size: 12px;
    }
  }
  tr:nth-child(odd) {
    .status-button {
      background: #46af38;
    }
  }
  tr:nth-child(even) {
    .status-button {
      background: #4684f5;
    }
  }
  height: 600px;
}

//  Responsive Table

@media screen and(max-width: 1199px) {
  .picklist-pdf {
    span,
    td {
      font-size: 13px;
    }
  }
}

//  SM devices
@media screen and(max-width: 767px) {
  .picklist-pdf {
    width: 700px;
    overflow: auto;
  }
}

.danger {
  color: $color-link-hover;
}
.f-right {
  float: right;
}
.f-left {
  float: left;
}
.text-center {
  text-align: center;
}
.consignment-rec-container {
  background: #fff;
  padding: 10px 0 50px;
  // width: calc(100% - 177px);

  &.full {
    width: 100%;
    clear: both;
    float: none;
    min-height: 700px;
  }

  float: left;
  header {
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 65px;
  }
  header:before,
  header:after {
    content: none;
  }
  .body-container-wrapper {
    width: 470px;
    margin: auto;
    padding: 10px 0;
  }
  .progress {
    position: relative;
    a {
      position: absolute;
      right: 5px;
      top: 10px;
      color: #000;
      text-decoration: underline;
    }
  }
  .progress p {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  .progress ul {
    list-style: none;
    @include flex();
    justify-content: space-between;
    margin: 0 0 40px;
    padding: 0;
    li {
      width: 23%;
      height: 2px;
      background: #d6d6d6;
      &.active {
        background: #4484f2;
      }
    }
  }
  h2 {
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
    letter-spacing: 1px;
  }
  .pager {
    @include flex;
    justify-content: space-around;
    align-items: center;
    width: 470px;
    margin: 0 auto 20px;
    font-size: 18px;
    font-weight: 700;
    .arrow-btn {
      display: inline-block;
      width: 0;
      height: 0;
      vertical-align: middle;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
    .next-btn {
      border-left: 15px solid #000;
    }
    .next-btn.disabled {
      border-left-color: #b5b5b5;
    }
    .prev-btn {
      border-right: 15px solid #000;
    }
    .prev-btn.disabled {
      border-right-color: #b5b5b5;
    }
  }

  .scan-steps ul {
    @include flex;
    margin: 0 0 70px;
    list-style: none;
    justify-content: space-around;
    li {
      background: #dfdfdf;
      border-radius: 100px;
      color: #999;
      cursor: pointer;
      font-size: 1.07rem;
      font-weight: 700;
      padding: 8px 5px;
      position: relative;
      text-align: center;
      width: 215px;
      z-index: 99;
      &.current-step {
        background: #47a0fb;
        color: #fff;
      }
      &:after {
        position: absolute;
        content: "";
        border-top: 2px solid #dfdfdf;
        width: 100%;
        right: -100%;
        top: 50%;
        z-index: 9;
      }
      &:last-child:after {
        content: none;
      }
      &.current-step:after {
        border-top-style: dotted;
        border-top-color: #47a0fb;
      }
    }
  }
  form {
    width: 100%;
    margin: auto;
  }
  .input-block {
    width: 470px;
    margin: 0 auto 20px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    box-shadow: 0 0 3px #e4e4e4;
    padding: 30px;
    overflow: hidden;
    .block-inner {
      position: relative;
      margin-bottom: 30px;
      padding-bottom: 30px;
      &:after {
        position: absolute;
        content: "";
        width: calc(100% + 60px);
        height: 1px;
        background: #c9c9c9;
        bottom: 0px;
        left: -30px;
      }
    }
    .block-inner:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      &:after {
        content: none;
      }
    }
    .input-group + .input-group {
      margin: 35px 0 0;
    }
    .input-group {
      position: relative;
      label {
        display: block;
        font-size: 12px;
        margin-bottom: 3px;
      }
      &.error {
        color: #b13b2d;
      }
      &.error span {
        color: #b13b2d !important;
      }
      #{$inputs-list} {
        background: #fff;
        border: none;
        border-bottom: 1px solid #c9c9c9;
        width: 100%;
        outline: none;
        padding: 0 2px;
      }
      &.error {
        #{$inputs-list} {
          border: 1px solid #b13b2d !important;
        }
      }
      select option {
        color: $color-default;
      }
      select option:first-child {
        color: #ccc;
      }
      input:focus {
        outline: none;
      }
      span {
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        color: #666;
        vertical-align: middle;
      }
      a.link {
        color: $color-link;
        font-size: 12px;
        text-decoration: underline;
        margin: 5px;
      }
      .radio-custom {
        float: right;
        label {
          background: #e9e9e9;
          display: inline-block;
          vertical-align: text-bottom;
          font-size: 13px;
          color: #666;
          font-weight: 700;
          min-width: 28px;
          height: 28px;
          padding: 0 2px;
          text-align: center;
          text-transform: uppercase;
          line-height: 28px;
          margin: 0 0 0 5px;
        }
        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
          position: absolute;
          left: -9999px;
        }
        [type="radio"]:checked + label {
          background: #666;
          color: #fff;
        }
      }

      .ref-icon {
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
      }
    }
    .attach-file {
      [type="file"] {
        width: 100%;
        opacity: 0;
        position: absolute;
        top: 16px;
        left: 0;
        z-index: 9;
      }
      &:after {
        content: "";
        width: 17px;
        height: 17px;
        background: url(assets/images/attach-icon.png);
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 10px;
      }
      .custom-attach {
        border: none;
        border-bottom: 1px solid #c9c9c9;
        width: 100%;
        outline: none;
        padding: 0 2px;
      }
      .files-box {
        color: #ccc;
        padding-bottom: 12px;
      }
      .mat-chip-list {
        position: relative;
        .mat-chip {
          padding-right: 25px;

          span {
            position: absolute;
            right: 10px;
            top: 5px;
          }
        }
      }
    }
  }
  .input-block.border {
    border: 1px solid #666;
  }
  .btn-block {
    max-width: 470px;
    margin: 0 auto 20px;
    .btn-wrapper {
      justify-content: space-between;
      .submit-button {
        padding: 7px 16px 7px 64px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        background: #fff;
        border: 2px solid #013334;
        position: relative;
        cursor: pointer;
        margin:0;
        &:hover {
          background: $color-default;
          color: #fff;
        }
        &:first-letter {
          margin: 0 16px 0 -64px;
          padding: 8px 15px 9px;
          background: #e9e9e9;
          color: $color-default;
        }
      }
    }
  }
}

.popup-form {
  .btn-wrapper {
    display: flex;
    justify-content: center;
    .submit-button {
      padding: 7px 16px 7px 61px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      background: #fff;
      border: 2px solid #013334;
      position: relative;
      cursor: pointer;
      margin: 0 15px;
      &:hover {
        background: $color-default;
        color: #fff;
      }
      &::before {
        content: none;
      }
      &:first-letter {
        margin: 0 16px 0 -64px;
        padding: 8px 15px 9px;
        background: #e9e9e9;
        color: $color-default;
      }
    }
  }
  .date-picker-cover {
    .mat-form-field-underline {
      height: 1px !important;
    }
  }
}

.header-search {
  .mat-form-field-infix {
    margin: 0;
    padding: 8px 6px 9px 10px;
    border: 1px solid #cfcfcf !important;
    span {
      color: #000;
    }
    .mat-form-field-ripple {
      background: transparent !important;
    }
  }

  .mat-select-arrow {
    border-color: #212529 !important;
  }
}

/* choose action page*/
.choose-action {
  width: 100%;
  min-height: 650px;
  margin: -10px 0 0 30px;
  clear: both;
  overflow: hidden;
  background: #fff;

  h3 {
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 600;
    background: #f5f5f5;
  }
  .inner-conent {
    padding: 60px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 305px;
        margin-right: 70px;
        min-height: 102px;
        display: flex;
        border: 1px solid #e4e4e4;
        margin-bottom: 70px;
        padding: 7px;
        background: #f5f5f5;
        transition: all linear 0.2s 0s;

        figure {
          margin: 0;
          background: #666;
          text-align: center;
          width: 30%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .right {
          width: 70%;
          padding: 0 0 0 10px;
          cursor: pointer;
          outline: none;
          h4 {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
          }
          p {
            margin: 5px 0;
            color: #999;
            line-height: 1.3;
          }
        }
        &:hover {
          transform: scale(1.05);
          figure {
            background: #000;
          }
        }
      }
    }
  }
}
/* popup css */
.overlay-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}
.popup-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  width: 580px;
  background: #fff;
  padding: 35px;

  a.close {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #212529;
    font-size: 20px;
  }
  .popup-content {
    color: #000;
    h2 {
      font-size: 22px;
      font-weight: 400;
      padding-bottom: 20px;
      color: #000;
      border: 1px solid transparent;
      border-bottom-color: #000;
      margin-bottom: 20px;
      overflow: hidden;
    }
    p {
      font-size: 14px;
      color: #999;
      padding-right: 50px;
    }
    .input-row {
      margin-bottom: 10px;
      width: 100%;
      [type="checkbox"] {
        width: 3%;
      }
      label {
        display: inline-block;
        width: 95%;
        padding: 0 0 0 5px;
        color: #333;
        vertical-align: top;
      }
      &.action-row {
        padding: 30px 0;
        [type="submit"] {
          float: right;
          border: 2px solid #333;
          background: #fff;
          padding: 5px 20px;
          font-weight: 700;
          font-size: 14px;
          cursor: pointer;
          &:hover {
            background: #333;
            color: #fff;
          }
        }
      }
    }
  }
}

/* selector css */

.filter-inner {
  background: #fff;
  width: 180px;
  padding: 20px;
  border: 1px solid #000;
  box-shadow: 0 0 10px #333;
  overflow: hidden;
  margin: 16px 0 0 -140px;
  [type="text"] {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }
  [type="submit"] {
    display: block;
    background: #007bff;
    color: #fff;
    border-radius: 2px;
    border: none;
    margin: 10px 5px 0;
    float: right;
  }
  &:before {
    content: "";
    position: absolute;
    top: 6px;
    left: -12px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #333;
  }
}

.datepicker {
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 57%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #333;
  }
  background: white;
  width: 500px;
  position: absolute;
  left: -250px;
  top: 58px;
  z-index: 999;
  border: 1px solid #000;
  padding: 15px;
  .calendar-left {
    width: 100%;
    float: left;
    margin-bottom: 10px;
    .mat-form-field {
      width: 47%;
      margin: 0 2% 0 0;
      .mat-form-field-wrapper {
        padding: 3px 15px 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
        background: #f1f1f1;
        font-size: 13px;
      }
    }
  }

  .calendar-right {
    float: right;
    width: 100%;
    ul {
      margin: 0 0 15px;
      li {
        margin: 0 1% 5px 1%;
        width: 48%;
        float: left;
        a {
          display: block;
          background: #f1f1f1;
          color: #448ee1;
          padding: 5px 15px;
          text-align: left;
          font-size: 14px;
          &:hover,
          &.active {
            background: #448ee1;
            color: #fff;
          }
        }
      }
    }
    button {
      display: block;
      background: #007bff;
      color: #fff;
      border-radius: 2px;
      border: none;
      margin: 10px 5px 0;
      float: right;
    }
  }
}

.acknowledgment {
  background: #fff;
  height: 100%;
  padding: 80px 0;
  .acknowledgment-block {
    max-width: 650px;
    margin: auto;
    border: 1px solid #000;
    h2 {
      text-align: center;
      font-size: 20px;
      border-bottom: 1px solid #000;
      padding: 20px 0;
      margin: 0;
    }
    .details-block {
      li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #000;
        line-height: 22px;
      }
      span {
        padding: 10px 10px;
        display: block;
        width: 50%;
        &:first-child {
          border-right: 1px solid #000;
        }
      }
    }
    .description {
      padding: 10px 15px;
      h3 {
        font-size: 16px;
        margin: 0;
      }
    }
    .signature {
      text-align: right;
      border-top: 1px solid #000;
      padding: 10px 5px;
      p {
        text-align: left;
        max-width: 40%;
        margin: 0 0 0 auto;
        line-height: 32px;
      }
    }
    .note {
      padding: 10px 15px;
      border-top: 1px solid #000;
    }
  }
}

.amz-form .mat-form-field-wrapper .mat-form-field-underline,
.mat-form-field-wrapper .mat-form-field-underline {
  height: 1px !important;
}
.amz-form .mat-form-field-wrapper .mat-select-arrow {
  border-color: #666;
}
.amz-form .mat-form-field {
  display: block;
}
.margin-style {
  margin-top: 40px;
}

.table-top {
  margin-bottom: 20px;
}

.tab-heading {
  width: 100%;
  background: #f5f5f5;
  padding: 20px;
  border-bottom: 1px solid #e4e4e4;

  h3 {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    margin: 0;
  }
}

.tab-body {
  padding: 0 30px;
}

.recall-main {
  width: 100%;

  label {
    min-width: 267px;
    display: inline-block;
    color: #999999;
    vertical-align: top;

    span {
      width: 14px;
      height: 14px;
      background: #999999;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      line-height: 14px;
      border-radius: 100%;
      font-size: 9px;
      margin-left: 9px;
    }
  }

  .form-control {
    max-width: 100px;
    border: 1px solid #cfcfcf;
    display: inline-block;
    margin-right: 10px;
    padding: 1px 10px;
    text-align: right;
    border-radius: 0;
    font-size: 14px;

    &:focus {
      box-shadow: none;
    }
  }

  span {
    color: #999999;
  }

  .shift-timing-container {
    display: inline-block;

    .shift-row {
      margin-bottom: 10px;

      &.text-right {
        text-align: right;
      }
    }

    .form-control {
      margin-right: 0;
      padding-right: 0;
      padding-left: 3px;
      max-width: 65px;
      text-align: left;
      border: 0;

      &::placeholder {
        color: #333333;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #333333;
      }

      &::-ms-input-placeholder {
        color: #333333;
      }
    }

    span {
      margin-left: 3px;
      display: inline-block;
      color: #333333;
    }

    input[type="number"] {
      &.form-control {
        &.show-arrow {
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            opacity: 1;
          }
        }
      }
    }

    .add-timing-btn {
      font-family: "Lato", sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #333333;
      display: inline-block;
      cursor: pointer;

      span {
        width: 21px;
        height: 21px;
        display: inline-block;
        background: #ea3340;
        border-radius: 100%;
        text-align: center;
        line-height: 21px;
        font-size: 18px;
        color: #ffffff;
        vertical-align: middle;
        margin: 0 4px 0 0;
      }
    }
    .minus-timing-btn {
      font-family: "Lato", sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #333333;
      display: inline-block;
      cursor: pointer;

      span {
        width: 21px;
        height: 21px;
        display: inline-block;
        background: #a9a9a9;
        border-radius: 100%;
        text-align: center;
        line-height: 21px;
        font-size: 18px;
        color: #ffffff;
        vertical-align: middle;
        margin: 0 4px 0 0;
      }
    }
  }

  &.wharehouse-main {
    .shift-timing-container {
      .form-control {
        & + span {
          margin: 0 10px 0 0;
        }
      }
    }
  }
}

.opening-days {
  li {
    width: 34px;
    height: 33px;
    line-height: 33px;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid #e4e4e4;
    display: inline-block;
    background: #f6f6f6;
    color: #363636;
    text-align: center;
    margin-right: 7px;
    cursor: pointer;

    &.disabled {
      background: #fdfdfd;
      color: #cecece;
      border-color: #f8f8f8;
    }
    &.disable-days {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

.shipment-main {
  .form-filters {
    border-bottom: 1px solid #e4e4e4;
    padding-top: 12px;

    .update-shortage-btn {
      font-family: "Lato", sans-serif;
      font-size: 12px;
      font-weight: 700;
      border: 2px solid #5c5c5c;
      color: #333333;
      padding: 5px 15px;
      cursor: pointer;
      float: right;
    }
  }

  .form-group {
    width: 100%;
    max-width: 235px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    margin-bottom: 10px;
  }
  .form-control {
    border-radius: 0;
    padding: 8px 10px 8px 33px;
    font-size: 12px;
    background: url("assets/images/search-icon.png") 10px center no-repeat;

    &:focus {
      box-shadow: none;
    }
  }

  .search-btn {
    width: 22px;
    height: 22px;
    background: url("assets/images/search-btn-icon.png") left center no-repeat;
    border: 0;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  .selected-filters {
    display: inline-block;
    span {
      margin-right: 5px;
      font-size: 14px;
      color: #666666;
    }

    a {
      margin-left: 10px;
      color: #666666;
      cursor: pointer;
    }
  }

  .table {
    font-size: 12px;
    border-spacing: 0;
    thead {
      border-bottom: 1px solid #e6e6e6;
      th {
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
        padding: 12px 8px;
        border-bottom: 1px solid #e6e6e6;
      }
    }

    tbody {
      td {
        color: #999999;
        padding: 7px 8px;
        border-bottom: 1px solid #e6e6e6;

        input {
          padding: 5px 10px;
        }

        input[type="number"] {
          max-width: 55px;
          padding-right: 0;

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            opacity: 1;
          }
        }
      }
    }
  }
}

.text-center {
  text-align: center;

  &.picklist-barcode {
    width: 100%;
  }
}
.tab-heading {
  display: flex;
  justify-content: space-between;
}
.edit-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: url("assets/images/edit-icon.png") no-repeat;
  transition: all 0.3s;
  &:hover {
    background-position-y: -21px;
  }
}

.edit-icon-active{
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: url("assets/images/edit-icon.png") no-repeat;
  transition: all 0.3s;
  
    background-position-y: -21px;
  
}

.edit-tick {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: url("assets/images/green-tick.png") no-repeat;
  transition: all 0.3s;
}

.custom-btn {
  border: 2px solid #000;
  padding: 8px 25px;
  background: #fff;
  font-family: "gotham";
  font-size: 12px;
  float: right;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    background: #000;
    color: #fff;
  }
}

.inword-popup {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.popup-body {
  background: #fff;
  padding: 35px;
  max-width: 900px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .close-popup {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    cursor: pointer;
    height: 25px;
    background: url("assets/images/x.png") no-repeat;
  }
  h2 {
    color: red;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 30px;
  }
  td,
  th {
    padding: 15px 10px;
  }
  td {
    color: #666;
    border-top: 1px solid #ccc;
  }
  table {
    border-collapse: collapse;
    margin-bottom: 30px;
  }
}

.inbound-section-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    flex: 0 0 40%;

    a {
      margin: 0;
      display: inline-block;
      border: 1px solid #adadad;
      background: #fff;
      padding: 3px 5px;
      margin-right: 5px;
    }
  }

  .inbond-filter-right {
    flex: 0 0 30%;
    display: flex;
    justify-content: space-between;

    .green-check-right {
      height: 25px;
      width: 25px;
    }

    .item-per-page {
      margin-right: 50px;
    }

    .total-pages {
      margin-right: 50px;
    }
  }
  .container-status {
    flex: 0 0 30%;
    p {
      font-weight: 600;
    }
  }

  .status {
    background: #6e43f9;
    color: #fff;
    border-radius: 4px;
    padding: 5px 15px;
    text-transform: uppercase;

    &.status-pending {
      background: #6e43f9;
      color: #fff;
    }
  }
}

.break-page {
  page-break-before: always;
}

.input-block {
  margin-bottom: 0px;
  .row {
    display: flex;
    align-items: flex-end;
    .flex-form {
      width: 50%;
      margin: 0 10px 0 0;
    }
    .flex-form:last-child {
      margin: 0;
    }
  }
  .flex-form {
    position: relative;
    span {
      position: absolute;
      right: 0;
    }
  }
}
.file-upload {
  border: none;
  border-bottom: 1px solid #c9c9c9;
  width: 100%;
  outline: none;
  padding: 0 2px;
}
.print-label {
  padding: 0px 40px;
  margin-top: 30px;
}
.print-label-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #ccc;
}
.print-label .print-label-inner:last-child {
  border-bottom: 1px solid #ccc;
}
.print-label-inner .print-label-content {
  font-size: 16px;
}
.print-label-inner .btn-print {
  padding: 4px 35px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  background: #fff;
  border: 2px solid #013334;
  position: relative;
  color: #113334;
  cursor: pointer;
  text-align: center;
  transition: 400ms ease-in;
}
.print-label-inner .btn-print:hover {
  background-color: #013334;
  color: #fff;
}

.btn-label-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn-label-proceed {
  padding: 7px 25px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  background: #fff;
  border: 2px solid #013334;
  position: relative;
  color: #113334;
  cursor: pointer;
  text-align: center;
  transition: 400ms ease-in;
  margin-right: -50px;
}

.btn-label-proceed:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.highlight-green span {

  font-weight: 600;
  color:#fff;
  padding:5px;
  background:green;
  border-radius: 5px;


}
.table-invoice-print thead {
  background-color: #ccc;
  -webkit-print-color-adjust: exact;
}
.table-invoice-print tbody tr:nth-child(even) {
  background-color: #ececec;
  -webkit-print-color-adjust: exact;
}

.no-border {
  border: 0 !important;
}

// resume recieving component  css
.res-recv-container {
  width: 100%;
  max-width: 640px;
  padding: 0 20px;
  margin: 0 auto;
  font-family: "Lato", sans-serif;
  color: #666666;
  .title {
    text-align: center;
    font-size: 23px;
    font-weight: normal;
  }
  .label-group {
    padding: 35px 0;
    display: flex;
    position: relative;
    justify-content: space-between;
    .lbl {
      color: #fff;
      flex: 0 0 50%;
      max-width: 216px;
      background-color:#aaa9a9;
      padding: 7px 10px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 17px;
      text-align: center;
      position: relative;
      z-index: 9;
      &.active{
        background-color: #47a0fb;
      }
    }
    .center-line{
      position: absolute;
      height: 2px;
      display: block;
      background: #dfdfdf;
      width: 100%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  form {
    padding: 36px 0 10px;
    .input-field {
      width: 100%;
      input {
        width: 100%;
        max-width: 100%;
        padding: 13px 16px;
        border: 1px solid #c6c6c6;
        font-size: 18px;
        border-radius: 5px;
        color: #333333;
        letter-spacing: 1.8px;
        margin-bottom: 6px;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #333333;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #333333;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #333333;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #333333;
        }
      }
    }
    .error-wrapper {
      p {
        font-size: 16.11px;
        color: #ea3340;
        letter-spacing: 0.7px;
      }
    }
  }
  .current-status {
    padding: 8px 0;
    p {
      font-size: 18px;
      color: #666666;
      letter-spacing: 0.5px;
    }
  }

  .d-none {
    display: none;
  }
  .action {
    p.action-title {
      margin-bottom: 0;
      letter-spacing: 0.8px;
    }
    .btn-action {
      margin-top: 5px;
      background-color: #f5f5f5;
      -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.22);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.22);
      padding: 17px 20px;
      display: block;
      cursor: pointer;
      h4 {
        font-size: 18px;
        color: #333333;
        font-weight: normal;
        margin-bottom: 0;
        letter-spacing: 0.5px;
      }
      p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 0;
        line-height: 20.14px;
        letter-spacing: 0.7px;
        max-width: 450px;
      }
    }
  }
}
// resume recieving component  css ends
.form-body.note-text{

}
  p.form-text{
    font-size: 14px;
    color:#666666;
  }
  .error-message
  {
    p {
      font-size: 16.11px;
      color: #ea3340;
      letter-spacing: 0.7px;
    }
  }

@media only screen and (max-width: 768px) {
  .body-container-wrapper {
    width: 100%;
    display: block;
    min-width: max-content;
}
}
